import React, { useState } from 'react';
import '../styles/AlertModal.css';

const ConfirmModal = ({ message, onClose, onConfirm, reload }) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleClose = () => {
        setIsOpen(false);
        if (reload) {
            window.location.reload();
        }
        onClose();
    };

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
        handleClose();
    };

    return (
        <>
            {isOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{message}</p>
                        <div>
                            <button className='child-save' onClick={handleConfirm}>Igen</button>
                            <button className='close-button' onClick={handleClose}>Mégse</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ConfirmModal;
