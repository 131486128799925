import React from 'react';

const FormattedDate = ({ text, date, showTime }) => {
  const formatDateTime = (inputDate) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };

    if (!showTime) {
      delete options.hour;
      delete options.minute;
    }

    const formattedDate = new Date(inputDate).toLocaleDateString(undefined, options);
    return formattedDate;
  };

  return <p>{text} {formatDateTime(date)}</p>;
};

export default FormattedDate;
