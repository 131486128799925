import React, { useEffect, useState } from "react";
import '../styles/Virtual.css'
import plakat1 from '../assets/plakat_1.png'
import plakat2 from '../assets/plakat_2.png'
import institutionInformation from '../assets/institutionInformation.docx'
import Adatkezeles from '../assets/Adatkezeles.docx'
import AdatkezelesTajekoztato from '../assets/Adatkezelesi_tajekoztato.doc'
import JogiNyil from '../assets/Jogi_nyilatkozat_dooki.pdf'

const PlusContent = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    return (
        <div className="virtual-container">
            <div className="virtual-content">
                <div className="title-back2">
                </div>
                <div className="title-back">
                    <h1 className="title">Virtuális tudásbázis</h1>
                </div>
                <div className="plakat-container">
                    <h2>Letölthető plakátok</h2>
                    <p>Az oldalon található plakátokat letöltheted, kinyomtathatod és kirakhatod a rendelőd várótermébe.</p>
                    <div className="plakatok">
                        <div>
                            <a className='plakat' href={plakat2} download="Plakat1">
                                <img className='plakat' src={plakat2} alt="Plakat2" />
                            </a>
                        </div>
                        <div>
                            <a className='plakat' href={plakat1} download="Plakat1">
                                <img className='plakat' src={plakat1} alt="Plakat1" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="docs">
                    <h2>Dokumentumok</h2>
                    <div className="docs-container">
                        <div className="docs-data">
                            <h4>Intézményi tájékoztató</h4>
                            <p>Amennyiben szükségesnek érzed, tájékoztasd a környezetedben lévő iskolák, óvodák vezetőit a digitális igazolásról. Segítségképp ehhez használhatod letölthető tájékoztatólevél sablonunkat:</p>
                            <a className='virtual-link' href={institutionInformation} download="intézmenyi_level_minta.docx">Minta intézményi tájékoztató letöltése</a>
                        </div>
                        <div className="docs-data">
                            <h4>Adatkezelési hozzájárulás</h4>
                            <p>Adatkezelő orvosként pácienseid adatainak kezelését jogszabály írja elő, viszont egyes adatokat (ilyen például a szülők e-mail címe) a szülők önkéntes hozzájárulása alapján kezelhetsz.</p>
                            <p>A szülők hozzájárulását neked kell beszerezned, a hozzájárulás történhet bármilyen módon, nincs formához kötve.</p>
                            <p>Ha írásbeli hozzájárulást kérsz a szülőktől, ehhez használhatod letölthető sablonunkat is:</p>
                            <a className='virtual-link' href={Adatkezeles} download="Adatkezelesi_hozzajarulas.docx" >Adatkezelési hozzájárulás letöltése</a>
                            <p>Ezt a dokumentumot nem kell eljuttatnod hozzánk, csak neked kell megőrizni.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <h2>Adatkezelési tájékoztató pácienseid számára</h2>
                    <div className="docs-data">
                        <p>Adatkezelő orvosként tájékoztatnod kell a pácienseket (kiskorúak esetén törvényes képviselőjét) az adatkezelésed rendjéről. Amennyiben van saját adatkezelési tájékoztatód, ne felejtsd el kibővíteni a Dooki-val (hivatalos adatainkat az Impresszum résznél találod).</p>
                        <p>Ha még nem rendelkezel adatkezelési tájékoztatóval, készítettünk Neked egy mintát, amit használni tudsz (tedd elérhetővé honlapodon vagy a rendelőbe pl. kifüggesztve):</p>
                        <a className='virtual-link' href={AdatkezelesTajekoztato} download="Adatkezelesi_tajekoztato.doc" >Adatkezelési tájékoztató minta orvosoknak</a>
                    </div>
                    <p className="nyilatkozatok">Az itt letölthető dokumentumok (Nyilatkozat, Orvosok adatkezelési tájékoztatója) használatáért a Dooki semmilyen felelősséget nem vállal. A dokumentumokat a Felhasználónak saját folyamataira személyre kell szabnia.</p>
                </div>
                <div>
                    <h2>Felhasználói kézikönyv</h2>
                    <div className="docs-data">
                        <p>Az alábbi linken keresztül érhető el a felhasználó kézikönyv.</p>
                        <a href="https://www.digitalisigazolas.hu/felhasznaloi-kezikonyv" target="_blank" rel="noopener noreferrer">Felhasználói kézikönyv</a>
                    </div>
                </div>
                <div className="impressum">
                    <h2>Impresszum</h2>
                    <div className="docs-data">
                        <h3>A rendszer üzemeltetője:</h3>
                        <div className="docs-data">
                            <div className="impressum-data">
                                <div>
                                    <p>név:</p>
                                    <p>székhely:</p>
                                    <p>adószám:</p>
                                    <p>nyilvántartási szám:</p>
                                    <p>e-mail cím:</p>
                                </div>
                                <div className="right-impressum-data">
                                    <p>Somlyó Balázs ev.</p>
                                    <p>9082 Nyúl Dózsa telep 10.</p>
                                    <p>41868115-1-28</p>
                                    <p>58108190</p>
                                    <p>kapcsolat@digitalisigazolas.hu</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="docs-data">
                        <h3>Tárhelyszolgáltató:</h3>
                        <div className="docs-data">
                            <div className="impressum-data">
                                <div>
                                    <p>név:</p>
                                    <p>székhely:</p>
                                    <p>cégjegyzékszám:</p>
                                    <p>adószám:</p>
                                    <p>e-mail cím:</p>
                                </div>
                                <div className="right-impressum-data2">
                                    <p>Rackforest Zrt.</p>
                                    <p>1132 Budapest, Victor Hugo utca 11. 5. em. B05001.</p>
                                    <p>01-10-142004</p>
                                    <p>32056842-2-41</p>
                                    <p>info@rackforest.hu </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contracts">
                    <div>
                        <a href="https://www.digitalisigazolas.hu/aszf/" target="_blank" rel="noopener noreferrer">Az aktuális Általános Szerződési Feltételeket ide kattintva éred el.</a>
                    </div>
                    <div>
                        <a href="https://www.digitalisigazolas.hu/adatvedelem/" target="_blank" rel="noopener noreferrer">Az aktuális Adatkezelési Tájékoztatót ide kattintva éred el.</a>
                    </div>
                    <button className='impressum-button2' onClick={handleOpenModal}>Impresszum</button>
                    {showModal && (
                        <div className="cookie-pdf-modal">
                            <div className="cookie-pdf-modal-content">
                                <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                                <iframe title="DookiCookie" src={JogiNyil} style={{ width: '100%', height: '80vh' }} />
                            </div>
                        </div>
                    )}
                    <p>© Minden jog fenntartva 2024. | Weboldalunk szerzői jogvédelem alatt áll. A honlapunkon elhelyezett szöveges, képi anyagok és tartalmi elemek felhasználása, másolása, továbbítása terjesztése, akár részben vagy egészben kizárólag a honlap üzemeltetőjének előzetes írásos beleegyezésével lehetséges.</p>
                </div>
            </div>
        </div>

    );
}

export default PlusContent;