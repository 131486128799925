import API_BASE_URL from './ApiConfig';

const addCertificate = async (certificateData) => {

  try {
    const formData = new FormData();
    formData.append('child_id', certificateData.child_id);
    formData.append('doctor_id', certificateData.doctor_id);
    formData.append('reason', certificateData.reason);
    formData.append('start_date', certificateData.start_date);
    formData.append('end_date', certificateData.end_date);
    formData.append('exempt_from_pe', certificateData.exempt_from_pe);
    formData.append('exemption_end_date', certificateData.exemption_end_date);
    formData.append('can_rejoin_community', certificateData.can_rejoin_community);
    formData.append('issue_date', certificateData.issue_date);
    formData.append('pin_code', certificateData.pin_code);

    if (certificateData.doctor_note) {
      formData.append('doctor_note', certificateData.doctor_note);
    }

    const response = await fetch(`${API_BASE_URL}/api/certificates`, {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    if (!response.ok) {
      if (response.status === 401) {
        throw new Error(401);
      } else {
        throw new Error('Failed to add certificate');
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error(error.message || 'An error occurred');
  }
};

export { addCertificate };
