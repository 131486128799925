import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { resetParentPassword } from '../api/ResetParentPassword';
import '../styles/ResetPassword.css';

const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetResult, setResetResult] = useState({ success: null, error: null });
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [message, setMessage] = useState('');

  const handleResetPassword = async () => {
    try {
      if (newPassword === confirmPassword) {
        const result = await resetParentPassword(token, newPassword);
        setResetResult(result);
        setMessage('Sikeres jelszóváltoztatás');
      } else {
        setPasswordsMatch(false);
      }
    } catch (error) {
      setResetResult({ success: false, error: 'Jelszóváltoztatás sikertelen.' });
    }
  };

  return (
    <div className='reset-container'>
      <div className='reset-form'>
        <h1 className="title2">Jelszó visszaállítása</h1>

        {resetResult.success === null && (
          <>
            <div className="field-picker">
              <input
                type="password"
                value={newPassword}
                placeholder='Adja meg az új jelszót'
                className='date-picker'
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>

            <div className="field-picker">
              <input
                type="password"
                value={confirmPassword}
                placeholder='Adja meg az új jelszót mégegyszer'
                className='date-picker'
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </>
        )}

        {!passwordsMatch && (
          <p className='password-error'>A jelszavaknak egyezniük kell!</p>
        )}

        {resetResult.success === null && (
          <button className='reg-button' onClick={handleResetPassword}>Jelszó visszaállítása</button>
        )}

        {resetResult.success !== null && (
          <div>
            {resetResult.success ? (
              <div className='success-container'>
                <p style={{ color: 'green' }}>{message}</p>
              </div>
            ) : (
              <p style={{ color: 'red' }}>{resetResult.error}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};



export default ResetPassword;
