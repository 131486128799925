import API_BASE_URL from './ApiConfig';

export const getCertificates = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/certificates`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch certificates');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
