import API_BASE_URL from './ApiConfig';

const resetPassword = async (token, newPassword) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/reset_password/${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({ new_password: newPassword }).toString(),
    });

    if (response.ok) {
      const result = await response.json();
      return result;
    } else if (response.status === 400) {
      return { error: 'Invalid or expired reset link' };
    } else {
      return { error: 'An unexpected error occurred' };
    }
  } catch (error) {
    return { error: 'An unexpected error occurred' };
  }
};

export { resetPassword };
