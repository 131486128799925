
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const handleExportClick = async (pdfContentRef, shouldDownload) => {
  try {
    const pdfDoc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    const scale = 3;

    const canvas = await html2canvas(pdfContentRef.current, {
      scale,
      width: 571,
      height: 762,
      logging: false,
      useCORS: true,
      imageTimeout: 15000,
      removeContainer: true,
    });

    pdfDoc.addImage(
      canvas.toDataURL('image/jpeg', 1),
      'JPEG',
      0,
      0,
      pdfDoc.internal.pageSize.width,
      pdfDoc.internal.pageSize.height
    );

    const pdfArrayBuffer = pdfDoc.output('arraybuffer');

    const pdfFile = new File([pdfArrayBuffer], 'dooki_igazolas.pdf', { type: 'application/pdf' });

    if (shouldDownload) {
      pdfDoc.save('dooki_igazolas.pdf');
    }

    return pdfFile;
  } catch (error) {
    throw error;
  }
};

export default handleExportClick;
