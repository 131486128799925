import API_BASE_URL from './ApiConfig';

async function changePin(doctorId, currentPin, newPin) {
    try {
        const formData = new FormData();
        formData.append('current_pin', currentPin);
        formData.append('new_pin', newPin);

        const response = await fetch(`${API_BASE_URL}/api/settings/change_pin/${doctorId}`, {
            method: 'PUT',
            body: formData,
            credentials: 'include'
        });

        const data = await response.json();

        if (response.ok) {
            return { success: true, message: data.message };
        } else {
            return { success: false, error: data.error };
        }
    } catch (error) {
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
    }
}

export default changePin;
