import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/Menu.css';

function MenuItem({ text, to, isSelected, onSelect }) {
  const menuItemClass = isSelected ? "menu-item selected" : "menu-item";
  
  return (
    <Link to={to} className={sessionStorage.getItem('accepted') === 'null' || sessionStorage.getItem('accepted') === 'false' ? 'disabled' : menuItemClass} onClick={() => onSelect(text)}>
      {text}
    </Link>
  );
}

function Menu() {
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const location = useLocation();

  const menuItems = useMemo(() => [
    { text: 'Igazolás kiállítása', to: '/' },
    { text: 'Jegyzet a szülőnek', to: '/jegyzet-szulonek' },
    { text: 'Korábbi igazolások', to: '/korabbi-igazolasok' },
    /*{ text: 'Igazolási kérések', to: '/igazolasi-keresek' },*/
    { text: 'Gyermekek', to: '/gyermekek' },
    { text: 'Statisztika', to: '/statisztika' },
    { text: 'Virtuális tudásbázis', to: '/virtualis-tudasbazis' },
  ], []);

  useEffect(() => {
    const storedItem = sessionStorage.getItem("selectedMenuItem");

    if (!selectedMenuItem && storedItem) {
      setSelectedMenuItem(storedItem);
    }
  }, [selectedMenuItem]);

  useEffect(() => {
    const isMenuPage = menuItems.some(item => item.to === location.pathname);

    if (isMenuPage) {
      setSelectedMenuItem(menuItems.find(item => item.to === location.pathname)?.text || "");
    }
  }, [location.pathname, menuItems]);

  useEffect(() => {
    sessionStorage.setItem("selectedMenuItem", selectedMenuItem);
  }, [selectedMenuItem]);

  return (
    <div className="menu">
      {menuItems.map((item, index) => (
        <MenuItem
          key={index}
          text={item.text}
          to={item.to}
          isSelected={selectedMenuItem === item.text}
          onSelect={(text) => setSelectedMenuItem(text)}
        />
      ))}
    </div>
  );
}

export default Menu;
