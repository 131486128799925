import API_BASE_URL from './ApiConfig';

const logout = async () => {
  try {  
    const response = await fetch(`${API_BASE_URL}/api/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });

    if (response.ok) {
      return { success: true, message: 'Logout successful' };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData.error };
    }
  } catch (error) {
    return { success: false, error: 'An error occurred during logout' };
  } finally {
    sessionStorage.removeItem('doctorId');
    sessionStorage.removeItem('accepted');
    sessionStorage.removeItem('hasToken');
    sessionStorage.removeItem('selectedMenuItem');
    localStorage.removeItem('EnableVirtual');
  }
};

export { logout };
