import React, { useState } from 'react';
import DookiCookie from '../assets/dooki_cookie.pdf';

const CookieModal = ({ onClose }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <div className="cookie-modal">
      <h1 className="title2">Sütik használata</h1>
      <p>Ez az oldal sütiket használ, az oldal megfelelő működése érdekében.</p>
      <p>További információkért kattints a további információk gombra.</p>
      <div className='cookie-buttons'>
        <button className="cookie-policy" onClick={handleOpenModal}>
          További információk
        </button>
        <button className="accept-cookie" onClick={onClose}>
          Rendben
        </button>
      </div>

      {showModal && (
        <div className="cookie-pdf-modal">
          <div className="cookie-pdf-modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <iframe title="DookiCookie" src={DookiCookie} style={{ width: '100%', height: '80vh' }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CookieModal;
