import API_BASE_URL from './ApiConfig';

const downloadTemplate = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/children/template`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('A letöltési folyamat sikertelen volt.');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'gyermekadat_template.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();

    return true;
  } catch (error) {
    return false;
  }
};

export default downloadTemplate;
