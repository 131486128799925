import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import Modal from 'react-modal';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/InputField.css';
import '../styles/pdf.css';
import { registerLocale } from "react-datepicker";
import hu from "date-fns/locale/hu";
import { getChildren } from '../api/AllChildren';
import { addCertificate } from '../api/CreateCerti';
import { fetchDoctorData } from '../api/DoctorId';
import AddChild from '../api/AddChild.js';
import AddChildModal from '../components/AddChildModal.js';
import '../styles/Certification.css';
import CertiSubmitModal from './CertiSubmitModal';
import CertiEmail from '../api/CertiEmail';
import PdfContent from '../components/pdf';
import handleExportClick from '../components/PdfExport';
import AlertModal from '../components/AlertModal.js';

function InputField() {
  registerLocale("hu", hu);
  const [name, setName] = useState('');
  const [birth, setBirth] = useState(null);
  const [childId, setchildId] = useState('');
  const [address, setAddress] = useState('');
  const [mothersName, setMothersName] = useState('');
  const [birthPlace, setBirthPlace] = useState('');
  const [reason, setReason] = useState('Betegség');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [PhysicalExcused, setPhysicalExcused] = useState(null);
  const [communityDate, setCommunityDate] = useState(null);
  const [excused, setExcused] = useState(false);
  const [showParentNote, setShowParentNote] = useState(false);
  const [doctorNote, setDoctorNote] = useState('');
  const [pin, setPin] = useState('');
  const [pinError, setPinError] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const pdfContentRef = useRef(null);
  const [successMessage, setSuccessMessage] = useState('');
  const maxCertiDate = new Date();
  const minCertiDate = new Date();
  maxCertiDate.setMonth(maxCertiDate.getMonth() + 6);
  minCertiDate.setMonth(minCertiDate.getMonth() - 6);
  const maxDate = new Date();
  const [children, setChildren] = useState([]);
  const [error, setError] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);
  const [selectedBirth, setSelectedBirth] = useState(null);
  const [doctorData, setDoctorData] = useState(null);
  const [isOneDaySickness, setIsOneDaySickness] = useState(false);
  const [savedUuid, setSavedUuid] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [noParentEmail, setNoParentEmail] = useState(false);
  const doctorId = parseInt(sessionStorage.getItem('doctorId'), 10);
  const [startDateError, setStartDateError] = useState('');
  const [reasonError, setReasonError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [selectedError, setSelectedError] = useState('');
  const [communityError, setCommunityError] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [birthOptions, setBirthOptions] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    birthdate: '',
    birthplace: '',
    address: '',
    reason: 'Betegség',
    mothers_name: '',
    parent_email: '',
    doctor_id: doctorId,
  });

  const resetState = () => {
    setName('');
    setBirth(null);
    setSelectedChild(null);
    setAddress('');
    setMothersName('');
    setBirthPlace('');
    setchildId('');
    setStartDate(null);
    setEndDate(null);
    setCommunityDate(null);
    setDoctorNote('');
    setPhysicalExcused(null);
    setSelectedBirth('')
    setReason('Betegség');
    setIsOneDaySickness(false);
    setPinError('');
    setPin('');
    setSavedUuid(null);
    setExcused(false);
    setShowParentNote(false);
    setShowDatePicker(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchChildrenData = async () => {
      try {
        const response = await getChildren();
        setChildren(response);
      } catch (error) {
        setError("Hiba történt a szerverrel való kommunikáció közben!");
        setShowErrorModal(true);
      }
    };
    fetchChildrenData();
  }, [savedUuid]);

  useEffect(() => {
    const options = children.map((child) => ({
      value: child,
      label: child.name,
    }));
    setOptions(options);
  }, [children]);

  useEffect(() => {
    const birthOptions = children.map((child) => ({
      value: child,
      label: `${child.birthdate} (${child.name})`,
    }));
    setBirthOptions(birthOptions);
  }, [children]);



  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddChildClick = () => {
    setShowModal(true);
  };

  const handleSaveClick = async () => {
    try {
      const response = await AddChild.addChild({
        ...formData,
        birthdate: selectedDate.toDateString(),
      });
      if (response.success) {
        setShowAlertModal(true);
        setShowModal(false);
        return { success: true };
      } else {
        return { success: false, error: response.error };
      }
    } catch (error) {
      setError("Hiba történt a szerverrel való kommunikáció közben!");
      setShowErrorModal(true);
      throw error;
    }
  };

  const openModal = () => {
    let isValid = true;
    let validationErrors = {};

    if (!selectedChild) {
      validationErrors = { ...validationErrors, child: 'Válassz ki egy gyermeket.' };
      setSelectedError('Válassz ki egy gyermeket');
      isValid = false;
    }

    if (!startDate) {
      validationErrors = { ...validationErrors, startDate: 'Add meg a kezdő dátumot.' };
      setStartDateError('Add meg a betegség kezdetét');
      isValid = false;
    }

    if (!reason || reason.length === 0) {
      validationErrors = { ...validationErrors, reason: 'Add meg a távolmaradás okát.' };
      setReasonError('Add meg a távolmaradás okát.');
      isValid = false;
    } else if (reason.length > 120) {
      validationErrors = { ...validationErrors, reason: 'A betegség kezdete nem lehet hosszabb, mint 120 karakter.' };
      setReasonError('Maximum 120 karakter lehet.');
      isValid = false;
    }

    if (!isOneDaySickness && !endDate) {
      validationErrors = { ...validationErrors, endDate: 'Add meg a befejező dátumot.' };
      setEndDateError('Add meg a betegség végét');
      isValid = false;
    }

    if (!communityDate) {
      validationErrors = { ...validationErrors, endDate: 'Add meg mikor mehet közösségbe.' };
      setCommunityError('Add meg mikor mehet közösségbe');
      isValid = false;
    }

    if (isValid) {
      setSuccessMessage('');
      setModalIsOpen(true);
    } else {
      console.log('Validációs hiba történt:', validationErrors);
    }
  };


  const closeModal = () => {
    setModalIsOpen(false);
    setSavedUuid(null);
    setSelectedError('');
    setStartDateError('');
    setEndDateError('');
    setCommunityError('');
    setReasonError('');
  };

  const updateCommunityDate = (selectedEndDate) => {
    if (selectedEndDate) {
      const newCommunityDate = new Date(selectedEndDate);
      newCommunityDate.setDate(newCommunityDate.getDate() + 1);
      setCommunityDate(newCommunityDate);
    }
  };

  useEffect(() => {
    const doctorId = parseInt(sessionStorage.getItem('doctorId'), 10);
    const fetchData = async () => {
      try {
        const data = await fetchDoctorData(doctorId);
        setDoctorData(data.doctor);
      } catch (err) {
        setError("Hiba történt a szerverrel való kommunikáció közben!");
        setShowErrorModal(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedChild) {
      setNoParentEmail(selectedChild.value.parent_email === '');
    }
  }, [selectedChild]);

  useEffect(() => {
    if (isOneDaySickness) {
      setEndDate(null);
      updateCommunityDate(startDate);
    }
  }, [isOneDaySickness, startDate]);

  const handleAddCertificate = async () => {
    let issueDate = null;
    try {
      if (!pin || pin.length !== 6) {
        setPinError('A PIN-nek 6 jegyűnek kell lennie.');
        return false;
      }
      issueDate = new Date().toLocaleString();
      const doctorId = parseInt(sessionStorage.getItem('doctorId'), 10);
      const certificateData = {
        child_id: childId,
        doctor_id: doctorId,
        reason: reason,
        start_date: startDate.toDateString(),
        end_date: endDate !== null ? endDate.toDateString() : startDate.toDateString(),
        exempt_from_pe: excused,
        exemption_end_date: PhysicalExcused !== null ? PhysicalExcused.toDateString() : null,
        can_rejoin_community: communityDate.toDateString(),
        issue_date: issueDate,
        pin_code: pin,
      };
      if (showParentNote) {
        certificateData.doctor_note = doctorNote;
      }
      const response = await addCertificate(certificateData);
      if (response && response.uuid) {
        setSavedUuid(response.uuid);
        setSuccessMessage('Igazolás sikeresen elküldve!');
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error.message === '401') {
        setPinError('Nem megfelelő PIN kód');
      } else {
        setError("Hiba történt a szerverrel való kommunikáció közben!");
        setShowErrorModal(true);
      }

      return false;
    }
  };

  useEffect(() => {
    const sendEmail = async (uuid) => {
      try {
        const pdfFile = await handleExportClick(pdfContentRef);
        if (pdfFile) {
          const result = await CertiEmail(pdfFile, doctorNote, childId, uuid);
          if (result.success) {
            console.log('Sikeres API hívás:');
          } else {
            console.error('Hiba az API hívás során:');
          }
        }
      } catch (error) {
        setError("Hiba történt a szerverrel való kommunikáció közben!");
        setShowErrorModal(true);
      }
    };

    if (savedUuid) {
      sendEmail(savedUuid);
    }
  }, [savedUuid, doctorNote, childId, pdfContentRef]);


  const handleSelectChange = (selectedOption) => {
    setSelectedChild(selectedOption);
    if (selectedOption) {
      setName(selectedOption.value.name);
      setBirth(new Date(selectedOption.value.birthdate));
      setAddress(selectedOption.value.address);
      setMothersName(selectedOption.value.mothers_name);
      setBirthPlace(selectedOption.value.birthplace);
      setchildId(selectedOption.value.id);
      setSelectedError('');

      const childrenWithSelectedName = children.filter(child => child.name === selectedOption.value.name);

      const updatedBirthOptions = childrenWithSelectedName.map(child => ({
        value: child,
        label: child.birthdate,
      }));

      setBirthOptions(updatedBirthOptions);

      setSelectedBirth(updatedBirthOptions.find(option => option.value.birthdate === selectedOption.value.birthdate));
    } else {
      setName('');
      setBirth(null);
      setSelectedBirth(null);
      setAddress('');
      setMothersName('');
      setBirthPlace('');
      setchildId('');
      const birthOptions = children.map((child) => ({
        value: child,
        label: `${child.birthdate} (${child.name})`,
      }));
      setBirthOptions(birthOptions);

      const options = children.map((child) => ({
        value: child,
        label: child.name,
      }));
      setOptions(options);
    }
  };

  const handleSelectBirthChange = (selectedOption) => {
    setSelectedBirth(selectedOption);
    if (selectedOption) {
      setName(selectedOption.value.name);
      setBirth(new Date(selectedOption.value.birthdate));
      setAddress(selectedOption.value.address);
      setMothersName(selectedOption.value.mothers_name);
      setBirthPlace(selectedOption.value.birthplace);
      setchildId(selectedOption.value.id);
      setSelectedError('');

      const childrenWithSelectedBirthDate = children.filter(child => child.birthdate === selectedOption.value.birthdate);
      const updatedOptions = childrenWithSelectedBirthDate.map((child) => ({
        value: child,
        label: child.name,
      }));
      setOptions(updatedOptions);

      if (childrenWithSelectedBirthDate.length > 0) {
        setSelectedChild({
          value: selectedOption.value,
          label: selectedOption.value.name,
        });
      }
    } else {
      setName('');
      setBirth(null);
      setSelectedChild(null);
      setAddress('');
      setMothersName('');
      setBirthPlace('');
      setchildId('');

      const birthOptions = children.map((child) => ({
        value: child,
        label: `${child.birthdate} (${child.name})`,
      }));
      setBirthOptions(birthOptions);

      const options = children.map((child) => ({
        value: child,
        label: child.name,
      }));
      setOptions(options);
    }
  };

  const handlePinSubmit = async () => {
    try {
      if (pin.length !== 6) {
        setPinError('A PIN-nek 6 jegyűnek kell lennie.');
        return;
      }
      const success = await handleAddCertificate();
      if (success) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        resetState();
      }
    } catch (error) {
      setError("Hiba történt a szerverrel való kommunikáció közben!");
      setShowErrorModal(true);
    }
  };

  const handleExportClickWrapper = () => {
    handleExportClick(pdfContentRef, true);
    resetState();
  };

  const handleExportAndAddCertificate = async () => {
    try {
      setPinError('');
      if (!pin || pin.length !== 6) {
        setPinError('A PIN-nek 6 jegyűnek kell lennie.');
        return;
      }
      const success = await handleAddCertificate();
      if (success) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await handleExportClickWrapper();
      }
    } catch (error) {
      setError("Hiba történt a szerverrel való kommunikáció közben!");
      setShowErrorModal(true);
    }
  };

  return (
    <div className="certi-field">
      <div className="back-content">
        <div className="back">
          <div className="field-picker">
            <div>Név</div>
            <Select
              options={options}
              isSearchable
              placeholder="Add meg a gyermek nevét"
              value={selectedChild}
              onChange={handleSelectChange}
              menuMaxHeight={20}
              isClearable
              className={!selectedError ? "" : "error-border"}
            />
            <div className="error-message">{selectedError}</div>
          </div>
          <div className="field-picker">
            <div>Születési dátum</div>
            <Select
              options={birthOptions}
              isSearchable
              placeholder="Add meg a születési dátumot"
              value={selectedBirth}
              onChange={handleSelectBirthChange}
              isDisabled={birthOptions.length === 1}
              menuMaxHeight={20}
              isClearable
              className={!selectedError ? "" : "error-border"}
            />
            <div className="error-message">{selectedError}</div>
          </div>

          <div className="field-picker">
            <div className="text-area-label">Távolmaradás oka</div>
            <textarea
              className={!reasonError ? "large-box" : "large-box-error"}
              placeholder="Add meg a távolmaradás okát"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
            <div className="error-message">{reasonError}</div>
          </div>

          <div>
            <div className='certi-picker'>
              <div>Betegség kezdete</div>
              <DatePicker
                className={!startDateError ? "date-picker" : "error-picker"}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Add meg a betegség kezdetét"
                calendarStartDay={1}
                locale="hu"
                dateFormat="yyyy/MM/dd"
                minDate={minCertiDate}
                maxDate={maxCertiDate}
              />
              <div className="error-message">{startDateError}</div>
            </div>
            <div>
              <input
                className="one-day"
                type="checkbox"
                id="oneDaySickness"
                checked={isOneDaySickness}
                onChange={() => {
                  setIsOneDaySickness(!isOneDaySickness);
                  if (isOneDaySickness) {
                    setEndDate(null);
                  }
                }}
              />
              <label htmlFor="oneDaySickness">Egynapos betegség</label>
            </div>
          </div>
          <div>
            <div className='certi-picker'>
              <div>Betegség vége</div>
              <DatePicker
                className={!endDateError ? "date-picker" : "error-picker"}
                selected={endDate}
                onChange={(date) => {
                  if (!isOneDaySickness) {
                    setEndDate(date);
                    updateCommunityDate(date);
                  }
                }}
                placeholderText="Add meg a betegség végét"
                minDate={startDate}
                calendarStartDay={1}
                locale="hu"
                dateFormat="yyyy/MM/dd"
                maxDate={maxCertiDate}
                disabled={isOneDaySickness}
              />
              <div className="error-message">{endDateError}</div>
            </div>
          </div>
          <div>
            <div className='certi-picker'>
              <div>Közösségbe mehet</div>
              <DatePicker
                className={!communityError ? "date-picker" : "error-picker"}
                selected={communityDate}
                onChange={(date) => setCommunityDate(date)}
                placeholderText="Közösségbe mehet ekkor..."
                minDate={
                  endDate !== null
                    ? new Date(endDate.getTime() + 24 * 60 * 60 * 1000)
                    : startDate !== null
                      ? new Date(startDate.getTime() + 24 * 60 * 60 * 1000)
                      : minCertiDate
                }
                calendarStartDay={1}
                locale="hu"
                dateFormat="yyyy/MM/dd"
                maxDate={maxCertiDate}
              />
              <div className="error-message">{communityError}</div>
            </div>
          </div>
          <div className="checkbox">
            <input
              type="checkbox"
              id="excused"
              checked={excused}
              onChange={() => {
                setExcused(!excused);
                setShowDatePicker(!excused);
              }}
            />
            <label className="PE" htmlFor="felmentve">
              Testnevelés alól felmentve
            </label>
            {showDatePicker && (
              <div className='certi-picker'>
                <DatePicker
                  className="date-picker"
                  placeholderText="Testnevelés alól felmentve eddig..."
                  minDate={communityDate}
                  selected={PhysicalExcused}
                  onChange={(date) => {
                    setPhysicalExcused(date);
                  }}
                  calendarStartDay={1}
                  locale="hu"
                  dateFormat="yyyy/MM/dd"
                  maxDate={maxCertiDate}
                />
              </div>
            )}
          </div>
          <div className="checkbox">
            <input
              type="checkbox"
              id="parentNote"
              checked={showParentNote}
              onChange={() => setShowParentNote(!showParentNote)}
            />
            <label className="PE" htmlFor="parentNote">
              Jegyzet a szülőnek
            </label>
            {showParentNote && (
              <div>
                <textarea
                  className="large-box"
                  placeholder="Adj hozzá jegyzetet a szülőnek"
                  value={doctorNote}
                  onChange={(e) => setDoctorNote(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Igazolás Modal"
          className="certi-modal"
        >
          <CertiSubmitModal
            pin={pin}
            setPin={setPin}
            pinError={pinError}
            successMessage={successMessage}
            handlePinSubmit={handlePinSubmit}
            handleExportClick={handleExportClickWrapper}
            handleExportAndAddCertificate={handleExportAndAddCertificate}
            closeModal={closeModal}
            emailEmpty={noParentEmail}
            setEmailEmpty={setNoParentEmail}
            childId={selectedChild ? selectedChild.value.id : null}
          />

        </Modal>
      </div>
      <div className="pdf-container">
        <div id="pdfExportContent">
          <PdfContent
            name={name}
            address={address}
            birthPlace={birthPlace}
            birth={birth}
            mothersName={mothersName}
            startDate={startDate}
            endDate={endDate}
            reason={reason}
            communityDate={communityDate}
            excused={excused}
            PhysicalExcused={PhysicalExcused}
            doctorData={doctorData}
            qr={savedUuid}
            con={pdfContentRef}
            isOneDaySickness={isOneDaySickness}
          />
        </div>
        <AddChildModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          maxDate={maxDate}
          formData={formData}
          handleChange={handleChange}
          handleSaveClick={handleSaveClick}
        />
        {error && showErrorModal && <AlertModal message={error} onClose={() => setShowErrorModal(false)} reload={false} />}
        {showAlertModal && <AlertModal message={"Gyermek adatai sikeresen mentve"} onClose={() => setShowAlertModal(false)} reload={true} />}
      </div>
      <div className='action-panel'>
        <button className='add-child' onClick={handleAddChildClick}>Gyermek hozzáadása</button>
        <button className="action-button" onClick={openModal}>
          Igazolás kiállítása
        </button>
      </div>
    </div>
  );
}

export default InputField;