import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { confirmParentEmail } from '../api/ConfirmParentEmail';
import '../styles/ConfirmEmail.css';
import NameLogo from '../assets/NameLogo.png'

function ConfirmParentEmail() {
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const { token } = useParams();

    useEffect(() => {
        confirmParentEmail(token)
            .then((data) => {
                if (data.success) {
                    setMessage('Az email sikeresen megerősítve.');
                    setSuccess(true);
                } else {
                    setMessage('Hiba történt, a link nem érvényes.');
                }
            });
    }, [token]);

    return (
        <div className='confirm-container'>
            <div className='confirm-box'>
            <img className='name-logo' src={NameLogo} alt="DookiLogo" />
                <h2 className='title1'>E-mail megerősítés</h2>
                <p className='confirm-message'>{message}</p>
                {success && <p className='confirm-message'>Most már bejelentkezhetsz az alkalmazásba</p>}
            </div>
        </div>
    );
}

export default ConfirmParentEmail;
