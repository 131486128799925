import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from "react-datepicker";
import hu from "date-fns/locale/hu";
import FormattedDate from './FormateDate';
import '../styles/Previous.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCalendar } from '@fortawesome/free-solid-svg-icons';

const CertificateFilter = ({ searchTerm, onSearchChange, certificates, childDetailsMap, handleCertificateClick, onDateChange, startDate, endDate }) => {
  registerLocale("hu", hu);

  const topRef = useRef(null);

  const filterCertificates = () => {
    return certificates.filter((certificate) => {
      const childId = certificate.child_id;
      const childDetails = childDetailsMap[childId];

      if (!childDetails) {
        return false;
      }

      const query = searchTerm.toLowerCase();
      const nameMatch = childDetails.name.toLowerCase().includes(query);
      //const tajMatch = childDetails.taj.toLowerCase().includes(query);
      const issueDateMatch = (!startDate || new Date(certificate.issue_date) >= startDate) &&
        (!endDate || new Date(certificate.issue_date) <= endDate);

      return (nameMatch /*|| tajMatch*/) && issueDateMatch;
    });
  };

  useEffect(() => {
    if (topRef.current) {
      window.scrollTo({
        top: topRef.current.offsetTop - 165,
        behavior: 'instant'
      });
    }
  }, [searchTerm]);

  return (
    <div className="previous-container">
      <div className="children-content">
        <h1 className="title">Korábbi igazolások</h1>
        <div className='filter-container2'>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              name="search"
              placeholder="Név szerinti szűrés"
              value={searchTerm}
              onChange={(e) => onSearchChange(e.target.value)}
              className="input-field"
              style={{ paddingLeft: '30px' }}
            />
            <FontAwesomeIcon icon={faSearch} style={{ position: 'absolute', left: '20px', top: '50%', color: 'gray', transform: 'translateY(-50%)' }} />
          </div>
          <div style={{ position: 'relative' }}>
            <DatePicker
              selected={startDate}
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              isClearable
              placeholderText="Szűrés kiállítás dátumától - dátumáig"
              calendarStartDay={1}
              locale="hu"
              dateFormat="yyyy/MM/dd"
              onChangeRaw={(e) => e.preventDefault()}
              className="datepicker-field"
              showYearDropdown
              yearDropdownItemNumber={100}
            />
            <FontAwesomeIcon icon={faCalendar} style={{ position: 'absolute', left: '16px', top: '50%', transform: 'translateY(-50%)', color: 'gray' }} />
          </div>
        </div>
      </div>

      <div ref={topRef}></div>

      {filterCertificates().map((certificate, index) => {
        const childId = certificate.child_id;
        const childDetails = childDetailsMap[childId] || {};

        return (
          <Link
            className='link'
            key={index}
            to={{
              pathname: `/certificate-details/${certificate.id}`,
              state: { certificate, childDetails },
            }}
          >
            <div className="result-box2" onClick={() => handleCertificateClick(certificate)}>
              <div className="data-container2">
                <p className='name-container'>{childDetails.name}</p>
                <div className='prev-data'>
                  <div className="left-data">
                    <div className='note-data-container'>
                      <p className='bold-text'>Születési dátum:</p>
                      <p className='certi-text'>{childDetails.birthdate}.</p>
                    </div>
                    <div className='note-data-container'>
                      <p className='bold-text'>Anyja neve:</p>
                      <p className='certi-text'>{childDetails.mothers_name}</p>
                    </div>
                    <div className='note-data-container'>
                      <p className='bold-text'>Távolmaradás oka:</p>
                      <p className='certi-text'>{certificate.reason}</p>
                    </div>
                  </div>
                  <div className="right-data">
                    <div className='note-data-container'>
                      <p className='bold-text'>Kiállíás dátuma:</p>
                      <div className='certi-text'><FormattedDate text='' date={certificate.issue_date} showTime={true} /></div>
                    </div>
                    <div className='note-data-container'>
                      <p className='bold-text'>Betegség kezdete:</p>
                      <p className='certi-text'>{certificate.start_date}.</p>
                    </div>
                    <div className='note-data-container'>
                      <p className='bold-text'>Betegség vége:</p>
                      <p className='certi-text'>{certificate.end_date}.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default CertificateFilter;
