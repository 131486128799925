import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Menu from './components/Menu';
import Certification from './pages/Certification';
import Note from './pages/Note';
import Previous from './pages/Previous';
import Statistics from './pages/Statistics';
import Help from './pages/Help';
import Settings from './pages/Settings';
import Subs from './pages/Subs';
import Login from './pages/Login';
import Registration from './pages/Registration';
import CertificateDetailsPage from './components/CertificateDetailsPage';
import ResetPassword from './pages/ResetPassword';
import ResetParentPassword from './pages/ResetParentPassword';
import ResetPin from './pages/ResetPin';
import Children from './pages/Children'
import Virtual from './pages/Virtual';
import CertiCheck from './pages/CertiCheck';
import ConfirmEmail from './pages/ConfirmEmail';
import ConfirmParentEmail from './pages/ConfirmParentEmail';
import CookieModal from './components/CookieModal';
import { logout } from './api/LogoutApi';
import CertiRequest from './pages/CertiRequest';

function App() {
  const [hasConsented, setHasConsented] = useState(localStorage.getItem('CONSENT') === 'true');
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('isAuthenticated') === 'true');
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [logoutReason, setLogoutReason] = useState(null);

  const handleAuthentication = useCallback((status) => {
    setIsAuthenticated(status);
    sessionStorage.setItem('isAuthenticated', status);
  }, []);

  const handleLogout = useCallback((reason) => {
    setIsAuthenticated(false);
    sessionStorage.setItem('isAuthenticated', 'false');
    setLogoutReason(reason);
    setRedirectToLogin(true);
  }, []);

  useEffect(() => {
    if (redirectToLogin) {
      setRedirectToLogin(false);
      setLogoutReason(null);
    }
  }, [redirectToLogin]);

  const handleCloseCookieModal = () => {
    setHasConsented(true);
    localStorage.setItem('CONSENT', 'true');
  };

  useEffect(() => {
    const calculateTimeUntilMidnight = () => {
      const now = new Date();
      const midnight = new Date(now);
      midnight.setHours(24, 0, 0, 0);
      return midnight - now;
    };
  
    let timeUntilMidnight = calculateTimeUntilMidnight();
  
    if (timeUntilMidnight < 0) {
      timeUntilMidnight += 24 * 60 * 60 * 1000;
    }
  
    const midnightTimer = setTimeout(async () => {
      setIsAuthenticated(false);
      setLogoutReason('Automatikus kijelentkezés éjfélkor');
      setRedirectToLogin(true);
      await logout();
    }, timeUntilMidnight);
  
    const interval = setInterval(() => {
      timeUntilMidnight = calculateTimeUntilMidnight();
      clearTimeout(midnightTimer);
      midnightTimer = setTimeout(async () => {
        setIsAuthenticated(false);
        setLogoutReason('Automatikus kijelentkezés éjfélkor');
        setRedirectToLogin(true);
        await logout();
      }, timeUntilMidnight);
    }, 24 * 60 * 60 * 1000);
  
    return () => {
      clearInterval(interval);
      clearTimeout(midnightTimer);
    };
  }, []);
  

  return (
    <Router>
      <div className="App">
        {isAuthenticated && <Header onLogout={() => handleLogout('User logged out')}/>}
        {isAuthenticated && <Menu />}
        {!hasConsented && <CookieModal onClose={handleCloseCookieModal} />}

        <div className="content">
          {logoutReason && <div className="logout-reason">{logoutReason}</div>}

          <Switch>
            <Route exact path="/reset_password/:token">
              <ResetPassword />
            </Route>
            <Route exact path="/parent_reset_password/:token">
              <ResetParentPassword />
            </Route>
            <Route exact path="/reset_pin/:token">
              <ResetPin />
            </Route>
            <Route exact path="/confirm/:token">
              <ConfirmEmail />
            </Route>
            <Route exact path="/parent-confirm/:token">
              <ConfirmParentEmail />
            </Route>
            <Route exact path="/ellenorzes">
              <CertiCheck />
            </Route>

            <Route path="/ellenorzes/:token">
              <CertiCheck />
            </Route>

            <Route path="/login">
              {!isAuthenticated ? <Login onAuthentication={handleAuthentication} /> : <Redirect to="/" />}
            </Route>

            <Route path="/registration">
              {!isAuthenticated ? <Registration /> : <Redirect to="/" />}
            </Route>

            {localStorage.getItem('EnableVirtual') ?
              <Route path="/virtualis-tudasbazis">
                <Virtual />
              </Route>
              :
              <Redirect to="/login" />
            }

            {!isAuthenticated && <Redirect to="/login" />}
            {isAuthenticated && (
              <>
                {(sessionStorage.getItem('accepted') === 'null' || sessionStorage.getItem('accepted') === 'false')  && (
                  <Route path="/Beállítások">
                    <Settings />
                  </Route>
                )}

                {sessionStorage.getItem('accepted') === 'true' && (
                  <>
                    <Route exact path="/">
                      <Certification />
                    </Route>
                    <Route path="/jegyzet-szulonek">
                      <Note />
                    </Route>
                    <Route path="/korabbi-igazolasok">
                      <Previous />
                    </Route>
                    <Route path="/igazolasi-keresek">
                      <CertiRequest />
                    </Route>
                    <Route path="/gyermekek">
                      <Children />
                    </Route>
                    <Route path="/statisztika">
                      <Statistics />
                    </Route>
                    <Route path="/virtualis-tudasbazis">
                      <Virtual />
                    </Route>
                    <Route path="/elofizetes">
                      <Subs />
                    </Route>
                    <Route path="/certificate-details/:certificateId">
                      <CertificateDetailsPage />
                    </Route>
                    <Route path="/Beállítások">
                      <Settings />
                    </Route>
                  </>
                )}      
                <Route path="/kapcsolat">
                  <Help />
                </Route>
                {(sessionStorage.getItem('accepted') === 'null' || sessionStorage.getItem('accepted') === 'false') &&
                <Redirect to={"/Beállítások"} />
                }
              </>
            )}
            <Redirect to="/" />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;

