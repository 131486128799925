import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getDoctorNotes } from '../api/AllNotes';
import { getDoctorNotesPages } from '../api/AllNotesPages.js';
import { getChildren } from '../api/AllChildren';
import Modal from 'react-modal';
import UpdateChild from '../api/UpdateChild.js'
import { addDoctorNote } from '../api/AddNote';
import CertificateDetails from '../components/Details';
import FormattedDate from '../components/FormateDate';
import DatePicker from 'react-datepicker';
import { registerLocale } from "react-datepicker";
import hu from "date-fns/locale/hu";
import Uhu from '../assets/Uhu.png'
import AlertModal from '../components/AlertModal.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCalendar } from '@fortawesome/free-solid-svg-icons';
import '../styles/Note.css';

const DoctorNotes = () => {
  const [notes, setNotes] = useState([]);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCertificateId, setSelectedCertificateId] = useState(null);
  const [newNote, setNewNote] = useState('');
  const [addNoteModalIsOpen, setAddNoteModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [children, setChildren] = useState([]);
  const [selectedChild, setSelectedChild] = useState(null);
  const [selectedBirth, setSelectedBirth] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterChildNameOrTaj, setFilterChildNameOrTaj] = useState('');
  const [selectedError, setSelectedError] = useState('');
  const [noteError, setNoteError] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showAddEmailModal, setShowAddEmailModal] = useState(false);
  const [birthOptions, setBirthOptions] = useState(null);
  const [options, setOptions] = useState(null);
  const [showAlertModal2, setShowAlertModal2] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);
  const [sortingOption, setSortingOption] = useState('issue_date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [isSorting, setIsSorting] = useState(false);
  const [noParentEmail, setNoParentEmail] = useState(false);
  const [childId, setchildId] = useState('');
  const doctorId = parseInt(sessionStorage.getItem('doctorId'), 10);
  const [noteSent, setNoteSent] = useState(false);
  const [formData, setFormData] = useState({
    parent_email: '',
  });

  registerLocale("hu", hu);

  Modal.setAppElement('#root');

  const sortingOptions = [
    { value: 'issue_date', label: 'Kiállítás dátuma' },
    { value: 'child_name', label: 'Név' },
  ];

  const handleSortButtonClick = () => {
    setIsSorting(!isSorting);
  };

  const handleSortingOptionChange = (event) => {
    setSortingOption(event.target.value);
  };

  const handleSortOrderChange = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    const options = children.map((child) => ({
      value: child,
      label: child.name,
    }));
    setOptions(options);
  }, [children, showAlertModal]);

  useEffect(() => {
    const birthOptions = children.map((child) => ({
      value: child,
      label: `${child.birthdate} (${child.name})`,
    }));
    setBirthOptions(birthOptions);
  }, [children, showAlertModal]);


  useEffect(() => {
    if (filterChildNameOrTaj && notes.length > 0) {
      window.scrollTo({ top: 0, behavior: 'instant' });
    }
  }, [filterChildNameOrTaj, notes]);

  useEffect(() => {
    const fetchChildrenData = async () => {
      try {
        setLoading(true);
        const response = await getChildren();
        setChildren(response);
        setLoading(false);
      } catch (error) {
        setError("Hiba történt a gyerekek lekérése közben!");
        setShowAlertModal2(true);
        setLoading(false);
      }
    };

    if (!children.length) {
      fetchChildrenData();
    }
  }, []);


  useEffect(() => {
    let searchTimer;

    const fetchDoctorNotes = async () => {
      try {
        const childrenMap = {};
        children.forEach((child) => {
          childrenMap[child.id] = child;
        });

        const doctorNotesResponse = await getDoctorNotes();
        const notesWithChildDetails = await Promise.all(
          doctorNotesResponse.map(async (note) => {
            const childDetails = childrenMap[note.child_id];
            return { ...note, childDetails };
          })
        );

        const filteredNotes = notesWithChildDetails.filter((note) => {
          const childDetails = note.childDetails;
          if (!childDetails) return true;

          const nameMatches = childDetails.name.toLowerCase().includes(filterChildNameOrTaj.toLowerCase());
          const isWithinDateRange = (!startDate || new Date(note.created_at) >= startDate) &&
            (!endDate || new Date(note.created_at) <= endDate);

          return nameMatches && isWithinDateRange;
        });
        setNotes(filteredNotes);
        setLoading(false);
      } catch (error) {
        setError("Hiba történt a jegyzetek lekérése közben!");
        setShowAlertModal2(true);
        setLoading(false);
      }
    };

    const fetchDoctorNotesPages = async () => {
      setLoading(false)
      try {
        const childrenMap = {};
        children.forEach((child) => {
          childrenMap[child.id] = child;
        });

        const doctorNotesResponse = await getDoctorNotesPages(page, sortingOption, sortOrder);
        const notesWithChildDetails = await Promise.all(
          doctorNotesResponse.notes.map(async (note) => {
            const childDetails = childrenMap[note.child_id];
            return { ...note, childDetails };
          })
        );

        setPagination(doctorNotesResponse.pagination);
        setNotes(notesWithChildDetails);
        setLoading(false);
      } catch (error) {
        setError("Hiba történt a jegyzetek lekérése közben!");
        setShowAlertModal2(true);
        setLoading(false);
      }
    };

    const handleSearch = () => {
      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        if (filterChildNameOrTaj !== '' || startDate !== null || endDate !== null) {
          fetchDoctorNotes();
        } else {
          fetchDoctorNotesPages();
        }
      }, 500);
    };

    if (children.length) {
      handleSearch();
      setNoteSent(false);
    }

    return () => {
      clearTimeout(searchTimer);
    };
  }, [children, filterChildNameOrTaj, startDate, endDate, page, sortingOption, sortOrder, noteSent]);

  const openModal = (certificateId) => {
    setSelectedCertificateId(certificateId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedCertificateId(null);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleAddNote = async () => {
    let isValid = true;
    let validationErrors = {};

    if (!selectedChild) {
      validationErrors = { ...validationErrors, child: 'Válasszon ki egy gyermeket.' };
      setSelectedError('Válassz ki egy gyermeket');
      isValid = false;
    }
    if (!newNote) {
      validationErrors = { ...validationErrors, child: 'Adjon meg legalább öt karakter hosszúságú üzenetet' };
      setNoteError('Adj meg egy legalább öt karakter hosszúságú üzenetet');
      isValid = false;
    }

    try {
      if (!selectedChild || !selectedChild.value) {
        return;
      }

      if (newNote.length < 5) {
        setNoteError('Adj meg egy legalább öt karakter hosszúságú üzenetet');
        return;
      }

      await addDoctorNote(newNote, selectedChild.value.id);
      setShowAlertModal(true);
      setAddNoteModalIsOpen(false);
      setSelectedChild(null);
      setSelectedBirth(null);
      setchildId('');
      setNewNote('');
      setNoteError('');
      setSelectedError('');
      setNoParentEmail(false);
      setNoteSent(true);

    } catch (error) {
      setError("Hiba történt a szerverrel való kommunikáció közben!");
      setShowAlertModal2(true);
    }
  };


  const handleDateChange = (dates) => {
    const [start, end] = dates;

    const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());

    const formattedStart = isValidDate(start) ? new Date(start.setHours(0, 0, 0, 0)) : null;
    const formattedEnd = isValidDate(end) ? new Date(end.setHours(23, 59, 59, 999)) : null;

    setStartDate(formattedStart);
    setEndDate(formattedEnd);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedChild(selectedOption);
    if (selectedOption) {
      setchildId(selectedOption.value.id);
      const childrenWithSelectedName = children.filter(child => child.name === selectedOption.value.name);

      const updatedBirthOptions = childrenWithSelectedName.map(child => ({
        value: child,
        label: child.birthdate,
      }));

      setBirthOptions(updatedBirthOptions);

      setSelectedBirth(updatedBirthOptions.find(option => option.value.birthdate === selectedOption.value.birthdate));

      // Check if selected child has no parent email
      if (!selectedOption.value.parent_email) {
        setNoParentEmail(true);
      } else {
        setNoParentEmail(false);
      }
    } else {
      setchildId('');
      setSelectedBirth(null);
      setNoParentEmail(false);
      const birthOptions = children.map((child) => ({
        value: child,
        label: `${child.birthdate} (${child.name})`,
      }));
      setBirthOptions(birthOptions);

      const options = children.map((child) => ({
        value: child,
        label: child.name,
      }));
      setOptions(options);
    }
  };


  const handleSelectBirthChange = (selectedOption) => {
    setSelectedBirth(selectedOption);
    if (selectedOption) {
      setchildId(selectedOption.value.id);
      const childrenWithSelectedBirthDate = children.filter(child => child.birthdate === selectedOption.value.birthdate);
      const updatedOptions = childrenWithSelectedBirthDate.map((child) => ({
        value: child,
        label: child.name,
      }));
      setOptions(updatedOptions);

      if (childrenWithSelectedBirthDate.length > 0) {
        setSelectedChild({
          value: selectedOption.value,
          label: selectedOption.value.name,
        });
      }
      if (!selectedOption.value.parent_email) {
        setNoParentEmail(true);
      } else {
        setNoParentEmail(false);
      }
    } else {
      setSelectedChild(null);
      setchildId('');
      setNoParentEmail(false);
      const birthOptions = children.map((child) => ({
        value: child,
        label: `${child.birthdate} (${child.name})`,
      }));
      setBirthOptions(birthOptions);

      const options = children.map((child) => ({
        value: child,
        label: child.name,
      }));
      setOptions(options);
    }
  };

  const handlePageChange = async (newPage) => {
    try {
      if (newPage !== page) {
        setPage(newPage);
        setSelectedPage(newPage);
        setLoading(true);
      }
    } catch (error) {
      console.error('Error fetching certificates:', error);
    }
  };

  const handleSaveClick = async () => {
    try {
      const dataToBeSent = {
        ...formData,
        doctor_id: doctorId,
      };
      const response = await UpdateChild.updateChild(childId, dataToBeSent)

      if (response.success) {
        setShowAddEmailModal(true);
        setNoParentEmail(false);
      } else {
        console.error('Mentés sikertelen', response.error);
      }
    } catch (error) {
      setError("Hiba történt a szerverrel való kommunikáció közben!");
      setShowAddEmailModal(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="doctor-notes-container">
      {loading ? (
        <img className='uhu' src={Uhu} alt="Loading..." />
      ) : (
        <>
          <div className='sorting-container'>
            <button className='sort-button' onClick={handleSortButtonClick}>
              Rendezés
            </button>
            {isSorting && (
              <div className="sort-options">
                <select name="field" value={sortingOption} onChange={handleSortingOptionChange}>
                  {sortingOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <button onClick={handleSortOrderChange}>
                  {sortOrder === 'asc' ? '▲' : '▼'}
                </button>
              </div>
            )}
          </div>
          <div className="note-container">
            <div className="children-content">
              <h1 className='title'>Jegyzetek</h1>
              <div className='filter-container2'>

                <>
                  <div style={{ position: 'relative' }}>
                    <input
                      type="text"
                      placeholder="Név szerinti szűrés"
                      value={filterChildNameOrTaj}
                      onChange={(e) => setFilterChildNameOrTaj(e.target.value)}
                      className="input-field"
                      style={{ paddingLeft: '30px' }}
                    />
                    <FontAwesomeIcon icon={faSearch} style={{ position: 'absolute', left: '20px', top: '50%', color: 'gray', transform: 'translateY(-50%)' }} />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <DatePicker
                      selected={startDate}
                      onChange={handleDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      isClearable
                      placeholderText="Szűrés kiállítás dátumától - dátumáig"
                      calendarStartDay={1}
                      locale="hu"
                      dateFormat="yyyy/MM/dd"
                      onChangeRaw={(e) => e.preventDefault()}
                      className="datepicker-field"
                      showYearDropdown
                      yearDropdownItemNumber={100}
                    />
                    <FontAwesomeIcon icon={faCalendar} style={{ position: 'absolute', left: '16px', top: '50%', transform: 'translateY(-50%)', color: 'gray' }} />
                  </div>
                </>
              </div>
            </div>
          </div>
          <div className='one-note-container'>
            {notes.map((note) => (
              <div key={note.id} className='one-note'>
                <div className='note-content'>
                  <div>
                    {note.childDetails && (
                      <>
                        <p><span className='bold-text'>Név: </span>{note.childDetails.name}</p>
                        <p><span className='bold-text'>Születési dátum: </span>{note.childDetails.birthdate}</p>
                      </>
                    )}
                  </div>
                  <p className='notes'>{note.note}</p>
                </div>
                <div className='certi-info'>
                  {note.certificate_id && (
                    <button className='certi-view-button' onClick={() => openModal(note.certificate_id)}>
                      Igazolás megtekintése
                    </button>
                  )}
                  <div className='note-date'>
                    <FormattedDate text='Létrehozva' date={note.created_at} />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Modal
            isOpen={addNoteModalIsOpen}
            onRequestClose={() => setAddNoteModalIsOpen(false)}
            className='add-note-modal'
          >
            <div>
              <h2 className='title2'>Jegyzet a szülőnek</h2>
              <div className='certi-picker'>
                {noParentEmail && (
                  <p className='warning2'>Figyelem: A szülőnek nincs megadva e-mail címe, ezért az igazolás csak az adatbázisban kerül rögzítésre, illetve letöltve adhatot oda a gyermeknek!</p>
                )}
                {noParentEmail &&
                  <div className='add-email'>
                    <input
                      type="text"
                      id="parent_email"
                      className='email-input'
                      placeholder='Szülő e-mail címe'
                      name="parent_email"
                      value={formData.parent_email}
                      onChange={handleChange}
                      required
                    />
                    <button className='add-child' onClick={handleSaveClick}>Email hozzáadása</button>
                  </div>
                }
                <div className="add-note-picker">
                  <div>Név</div>
                  <Select
                    options={options}
                    isSearchable
                    placeholder="Add meg a gyermek nevét"
                    value={selectedChild}
                    onChange={handleSelectChange}
                    menuMaxHeight={20}
                    isClearable
                    className={!selectedError ? "" : "error-border"}
                  />
                  <div className="error-message">{selectedError}</div>
                </div>

                <div className="add-note-picker">
                  <div>Születési dátum</div>
                  <Select
                    options={birthOptions}
                    isSearchable
                    placeholder="Add meg a születési dátumot"
                    value={selectedBirth}
                    onChange={handleSelectBirthChange}
                    isDisabled={birthOptions.length === 1}
                    menuMaxHeight={20}
                    isClearable
                    className={!selectedError ? "" : "error-border"}
                  />
                  <div className="error-message">{selectedError}</div>
                </div>
              </div>

              <textarea
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                placeholder="Adj hozzá jegyzetet..."
                className={!noteError ? "modal-text" : "modal-text-error"}
              />
              <div className="error-message">{noteError}</div>
            </div>
            <button className='save-button' onClick={handleAddNote}>Jegyzet küldése a szülőnek e-mailben</button>
          </Modal>

          <CertificateDetails
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            certificateId={selectedCertificateId}
          />
          {pagination && startDate === null && filterChildNameOrTaj === '' && (
            <div className="pagination-notes">
              {Array.from({ length: pagination.total_pages }, (_, index) => (
                <button
                  className={`page-select-button ${selectedPage === index + 1 ? 'selected-page' : ''}`}
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )}
        </>
      )}
      {showAlertModal && <AlertModal message={"Jegyzet sikeresen elküldve"} onClose={() => setShowAlertModal(false)} reload={false} />}
      {showAddEmailModal && <AlertModal message={"Email sikeresen mentve"} onClose={() => setShowAddEmailModal(false)} reload={false} />}
      {error && showAlertModal2 && <AlertModal message={error} onClose={() => setShowAlertModal2(false)} reload={false} />}

      <div className='action-panel'>
        <button className="add-note-button" onClick={() => setAddNoteModalIsOpen(true)}>
          Jegyzet készítése a szülőnek
        </button>
      </div>
    </div>
  );
};

export default DoctorNotes;
