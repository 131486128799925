import API_BASE_URL from './ApiConfig';

export const getChildrenPages = async (page, sortBy, order) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/children_pages?page=${page}&per_page=${20}&sort_by=${sortBy}&order=${order}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
    },
    credentials: 'include'
  });

    if (!response.ok) {
      throw new Error('Failed to fetch certificates');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

