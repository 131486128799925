import React, { useState, useEffect } from 'react';
import { fetchDoctorData } from '../api/DoctorId';
import { sendContactMessage } from '../api/Help.js';
import Scrolling from '../components/Scrolling.js';
import '../styles/Help.css';
import Select from 'react-select';
import AlertModal from '../components/AlertModal.js';

const Help = () => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [noteError, setNoteError] = useState(null);
    const [doctorData, setDoctorData] = useState(null);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { value: 'Hibajelentés', label: 'Hibajelentés' },
        { value: 'Kérdés a rendszer működésével kapcsolatban', label: 'Kérdés a rendszer működésével kapcsolatban' },
        { value: 'Általános kérdés', label: 'Általános kérdés' },
        { value: 'Egyéb', label: 'Egyéb' }
    ];

    const isScrollingDown = Scrolling();

    useEffect(() => {
        const doctorId = parseInt(sessionStorage.getItem('doctorId'), 10);
        const fetchData = async () => {
            try {
                const data = await fetchDoctorData(doctorId);
                setDoctorData(data.doctor);
            } catch (err) {
                console.error('Error:', err);
            }
        };
        fetchData();
    }, []);

    const handleSubmit = async () => {
        try {
            if (message.length < 5) {
                setNoteError('Adj meg egy legalább öt karakter hosszúságú üzenetet');
                return;
            }

            const response = await sendContactMessage(doctorData.name, doctorData.login_email, message, selectedOption);

            setMessage('');
            setSelectedOption(null);
            setShowAlertModal(true);
        } catch (error) {
            setError("Hiba történt a szerverrel való kommunikáció közben!");
            setShowErrorModal(true);
        }
    };

    return (
        <div className={`${isScrollingDown ? 'scroll' : ''}`}>
            <div className='contact-container'>
                <h2 className="title3">Kapcsolat</h2>
                <div className='contact-field '>
                    <div className="contact-container-left">
                        <p className='help-note' >Elakadtál? Esetleg technikai segítségre van szükséged?</p>
                        <p>Írj nekünk az űrlap segítségével és munkatársaink hamarosan felveszik veled a kapcsolatot és segítenek.</p>
                    </div>
                    <div className="contact-container-right">
                        <h4>Írj nekünk</h4>
                        <div className='field-picker'>
                            <Select
                                className='help-select'
                                value={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                                placeholder='Megkeresés oka'
                            />
                        </div>
                        <div className='field-picker'>
                            <textarea className={!noteError ? "contact-text" : "contact-text-error"} placeholder='Írj nekünk üzenetet...' value={message} onChange={(e) => setMessage(e.target.value)} />
                            {noteError && <p className='error-message'>{noteError}</p>}
                        </div>
                        <div>
                            <button className='child-save' onClick={handleSubmit}>Küldés</button>
                        </div>
                    </div>
                </div>
            </div>
            {showAlertModal && <AlertModal message={"Üzenet sikeresen elküldve"} onClose={() => setShowAlertModal(false)} reload={false} />}
            {error && showErrorModal && <AlertModal message={error} onClose={() => setShowErrorModal(false)} reload={false} />}
        </div>
    );
};

export default Help;
