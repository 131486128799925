import API_BASE_URL from './ApiConfig';

async function changePassword(doctorId, currentPassword, newPassword) {
    try {
        const formData = new FormData();
        formData.append('current_password', currentPassword);
        formData.append('new_password', newPassword);

        const response = await fetch(`${API_BASE_URL}/api/settings/change_password/${doctorId}`, {
            method: 'PUT',
            body: formData,
            credentials: 'include'
        });

        const data = await response.json();

        if (response.ok) {
            return { success: true, message: data.message };
        } else {
            return { success: false, error: data.error };
        }
    } catch (error) {
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
    }
}

export default changePassword;
