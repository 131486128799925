import API_BASE_URL from './ApiConfig';

export const sendContactMessage = async (name, email, message, selectedOption) => {
    try {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('selectedOption', selectedOption.value);

        const response = await fetch(`${API_BASE_URL}/api/contact`, {
            method: 'POST',
            body: formData,
            credentials: 'include'
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to send message');
        }
    } catch (error) {
        throw error;
    }
};