import API_BASE_URL from './ApiConfig';

async function confirmParentEmail(token) {
    try {
        const response = await fetch(`${API_BASE_URL}/api/parent/confirm/${token}`);
        const data = await response.json();

        if (response.ok) {
            return { success: data.success };
        } else {
            return { error: data.error };
        }
    } catch (error) {
        return { error: 'An error occurred while confirming email' };
    }
}

export { confirmParentEmail };