import API_BASE_URL from './ApiConfig';

const handleForgotPassword = async (email) => {
  try {
    const formData = new URLSearchParams();
    formData.append('email', email);

    const response = await fetch(`${API_BASE_URL}/api/forgot_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, message: data.message };
    } else {
      return { error: data.error || 'An unexpected error occurred' };
    }
  } catch (error) {
    return { error: 'An unexpected error occurred' };
  }
};

export { handleForgotPassword };
