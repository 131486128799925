import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const ChildCard = ({ child, handleEditClick, handleDeleteClick }) => {
  return (
    <div className="child-card-container2">
      <div className="child-card2">
        <div className="data-row">
          <div className="data-cell">{child.name}</div>
          <div className="data-cell">{child.birthdate}</div>
          <div className="data-cell">{child.birthplace}</div>
          <div className="data-cell">{child.mothers_name}</div>
          <div className="data-cell">
            <FontAwesomeIcon className='edit-button' icon={faEdit} onClick={() => handleEditClick(child)} />
            <FontAwesomeIcon className='delete-child-button' icon={faTrashAlt} onClick={() => handleDeleteClick(child.id)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChildCard;
