import API_BASE_URL from './ApiConfig';

const getChildren = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/children`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch children');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message || 'An error occurred');
  }
};

export { getChildren };

