import React, { useState } from 'react';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddChildModal = ({ showModal, setShowModal, selectedDate, setSelectedDate, maxDate, formData, handleChange, handleSaveClick }) => {
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const errorsObj = {};

        if (!formData.name) {
            errorsObj.name = 'Add meg a gyermek nevét';
        }
        if (!selectedDate) {
            errorsObj.selectedDate = 'Add meg a gyermek születési dátumát';
        }
        if (!formData.birthplace) {
            errorsObj.birthplace = 'Add meg a gyermek születési helyét';
        }
        if (!formData.address) {
            errorsObj.address = 'Add meg a gyermek lakcímét';
        }
        if (!formData.mothers_name) {
            errorsObj.mothers_name = 'Add meg a gyermek anyjának nevét';
        }
        /*if (!formData.taj) {
            errorsObj.taj = 'Add meg a gyermek TAJ-számát';
        }
        if (formData.taj.length !== 9 && formData.taj) {
            errorsObj.taj = 'Nem megfelelő TAJ-szám';
        }*/

        setErrors(errorsObj);

        if (Object.keys(errorsObj).length > 0) {
            return false;
        }

        return true;
    };

    const onSaveClick = async () => {
        const isValid = validateForm();
    
        if (isValid) {
            try {
                await handleSaveClick();
            } catch (error) {
                throw error;
            }
        }
    };

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            className='add-child-modal'
            contentLabel="Gyermek hozzáadása"
        >
            <h2 className='title2'>Gyermek Adatai</h2>
            <div className='certi-picker'>
                <input
                    type="text"
                    id="name"
                    className={errors.name ? "error-picker" : "date-picker"}
                    placeholder="Név"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                {errors.name && <div className="error-message">{errors.name}</div>}
            </div>

            <div className='certi-picker'>
                <DatePicker
                    className={errors.selectedDate ? "error-picker" : "date-picker"}
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    placeholderText="Születési dátum"
                    maxDate={maxDate}
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    calendarStartDay={1}
                    locale="hu"
                    dateFormat="yyyy/MM/dd"
                />
                {errors.selectedDate && <div className="error-message">{errors.selectedDate}</div>}
            </div>

            <div className='certi-picker'>
                <input
                    type="text"
                    id="birthplace"
                    className={errors.birthplace ? "error-picker" : "date-picker"}
                    placeholder='Születési hely'
                    name="birthplace"
                    value={formData.birthplace}
                    onChange={handleChange}
                    required
                />
                {errors.birthplace && <div className="error-message">{errors.birthplace}</div>}
            </div>

            <div className='certi-picker'>
                <input
                    type="text"
                    id="address"
                    className={errors.address ? "error-picker" : "date-picker"}
                    placeholder='Lakcím'
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                />
                {errors.address && <div className="error-message">{errors.address}</div>}
            </div>

            <div className='certi-picker'>
                <input
                    type="text"
                    id="mothers_name"
                    className={errors.mothers_name ? "error-picker" : "date-picker"}
                    placeholder='Anyja Neve'
                    name="mothers_name"
                    value={formData.mothers_name}
                    onChange={handleChange}
                    required
                />
                {errors.mothers_name && <div className="error-message">{errors.mothers_name}</div>}
            </div>

            {/*<div className='certi-picker'>
                <input
                    type="text"
                    id="taj"
                    className={errors.taj ? "error-picker" : "date-picker"}
                    placeholder='TAJ-szám'
                    name="taj"
                    value={formData.taj}
                    onChange={handleChange}
                    required
                />
                {errors.taj && <div className="error-message">{errors.taj}</div>}
            </div>*/}

            <div className='certi-picker'>
                <input
                    type="text"
                    id="parent_email"
                    className='date-picker'
                    placeholder='Szülő e-mail címe'
                    name="parent_email"
                    value={formData.parent_email}
                    onChange={handleChange}
                />
            </div>

            <div>
                <button className='child-save' onClick={onSaveClick}>Mentés</button>
                <button className='close-button' onClick={() => setShowModal(false)}>Bezárás</button>
            </div>
        </Modal>
    );
}

export default AddChildModal;
