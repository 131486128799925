import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import Scrolling from '../components/Scrolling.js';
import InputField from '../components/InputField.js';


Modal.setAppElement('#root');

const Certification = () => {

  const isScrollingDown = Scrolling();

  return (
   
    <div className={`${isScrollingDown ? 'scroll' : ''}`}>
        <h1 className="title3">Igazolás kiállítása</h1>
        <div className="certification-container">
        <InputField />
      </div>
    </div>

  );


};

export default Certification;
