import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import fetchCertificateRequests from '../api/CertiRequest';
import acceptRequest from '../api/AcceptRequest';
import { getChildDetails } from '../api/ChildId';
import '../styles/CertiRequest.css';
import { rejectCertificateRequest } from "../api/RejectRequest";
import Uhu from '../assets/Uhu.png'
import AlertModal from '../components/AlertModal'

const CertiRequest = () => {
    const [certificateRequests, setCertificateRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filterType, setFilterType] = useState('all');
    const [selectedRequests, setSelectedRequests] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [pin, setPin] = useState('');
    const [reason, setReason] = useState("");
    const [selectedRequestId, setSelectedRequestId] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState('pending');
    const [acceptAll, setAcceptAll] = useState(false);
    const [resultMessage, setResultMessage] = useState("");
    const [noRequestsMessage, setNoRequestsMessage] = useState("");
    const [serverError, setServerError] = useState("");
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [pinError, setPinError] = useState('');

    useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setNoRequestsMessage("");
    }, [certificateRequests]);

    const fetchData = async () => {
        try {
            const requests = await fetchCertificateRequests();
            const requestsWithChildData = await Promise.all(requests.map(async (request) => {
                const childData = await getChildDetails(request.child_id);
                return { ...request, childData };
            }));
            setCertificateRequests(requestsWithChildData);
            setLoading(false);
        } catch (error) {
            setShowAlertModal(true);
            setServerError("Hiba a szerverrel történő kommunikációs során!");
            setError(error.message);
            setLoading(false);
        }
    };

    const handleFilter = (type) => {
        setFilterType(type);
        setSelectedFilter(type);
        window.scrollTo(0, 0);
    };

    const getButtonStyle = (type) => {
        return type === selectedFilter ? 'request-filter-button active' : 'request-filter-button';
    };

    const handleAcceptRequest = async (requestId) => {
        setSelectedRequests([requestId]);
        setShowModal(true);
        setAcceptAll(false);
    };

    const handleSelectRequest = (requestId) => {
        setSelectedRequests(prevSelected => {
            if (prevSelected.includes(requestId)) {
                return prevSelected.filter(id => id !== requestId);
            } else {
                return [...prevSelected, requestId];
            }
        });
    };

    const handleAcceptSelectedRequests = async () => {
        setShowModal(true);
        setAcceptAll(false);
    };

    const handleAcceptAllRequests = async () => {
        const pendingRequests = certificateRequests
            .filter(request => request.accepted === null)
            .map(request => request.id);
        setSelectedRequests(pendingRequests);
        setShowModal(true);
        setAcceptAll(true);
    };

    const handleConfirmPinAndAccept = async () => {
        try {
            if (!pin || pin.length !== 6) {
                setPinError('A PIN-nek 6 jegyűnek kell lennie.');
                return false;
            }
            await Promise.all(selectedRequests.map(requestId => acceptRequest(requestId, pin)));
            fetchData();
            setSelectedRequests([]);
            setPin('');
            setAcceptAll(false);
            setResultMessage(acceptAll ? "Az összes igazolási kérelem sikeresen elfogadva!" : "Igazolási kérelmek sikeresen elfogadva!");
        } catch (error) {
            if (error.message === 'Hibás pin kód') {
                setPinError('Nem megfelelő PIN kód');
            } else {
                setError("Hiba történt a szerverrel való kommunikáció közben!");
            }

            return false;
        }
    };

    const handleRejectRequest = async (requestId) => {
        setSelectedRequestId(requestId);
        setShowRejectModal(true);
    };

    const handleConfirmReject = async () => {
        try {
            if (!pin || pin.length !== 6) {
                setPinError('A PIN-nek 6 jegyűnek kell lennie.');
                return false;
            }
            await rejectCertificateRequest(selectedRequestId, pin, reason);
            fetchData();
            setReason("");
            setPin("");
            setResultMessage("Igazolási kérelem sikeresen elutasítva!");
        } catch (error) {
            console.log(error)
            console.log(error.message)
            console.log(error.status)
            if (error.message === 'Hibás pin kód') {
                setPinError('Nem megfelelő PIN kód');
            } else {
                setError("Hiba történt a szerverrel való kommunikáció közben!");
            }

            return false;
        }
    };

    const filteredRequests = certificateRequests.filter(request => {
        if (filterType === 'pending') return request.accepted === null;
        if (filterType === 'accepted') return request.accepted === true;
        if (filterType === 'rejected') return request.accepted === false;
        return request.accepted === null;
    });

    if (loading) {
        return (
            <div className='loading'>
                <img className='uhu' src={Uhu} alt="Loading" />
            </div>
        )
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const handleCloseModal = () => {
        setResultMessage("");
        setShowModal(false);
        setShowRejectModal(false);
        setPin("");
        setPinError("");
    };

    return (
        <div className='request-container'>
            {serverError && showAlertModal && <AlertModal message={serverError} onClose={() => setShowAlertModal(false)} reload={false} />}
            {filteredRequests.length === 0 && (
                <div className="no-requests-message">
                    Jelenleg nincs igazolási kérés.
                </div>
            )}
            <Modal
                isOpen={showModal}
                onRequestClose={handleCloseModal}
                className="request-modal"
            >
                {resultMessage &&
                    <div>
                        <p>{resultMessage}</p>
                        <button className="request-alert-button" onClick={handleCloseModal}>Ok</button>
                    </div>
                }
                {!resultMessage && (
                    <>
                        <p>Add meg a PIN kódodat!</p>
                        <input
                            type="password"
                            placeholder="PIN kód"
                            value={pin}
                            onChange={(e) => setPin(e.target.value)}
                        />
                        <p className='error-message2'>{pinError}</p>
                        <button className="certi-confirm-button" onClick={handleConfirmPinAndAccept}>
                            {acceptAll ? 'Összes kérelem elfogadása' : 'Elfogadás'}
                        </button>
                    </>
                )}
            </Modal>

            <Modal
                isOpen={showRejectModal}
                onRequestClose={handleCloseModal}
                className="request-modal"
            >
                {resultMessage &&
                    <div>
                        <p>{resultMessage}</p>
                        <button className="child-save" onClick={handleCloseModal}>Ok</button>
                    </div>
                }
                {!resultMessage && (
                    <>
                        <p>Add meg a PIN kódodat!</p>
                        <input
                            type="password"
                            placeholder="PIN kód"
                            value={pin}
                            onChange={(e) => setPin(e.target.value)}
                        />
                        <textarea
                            className='request-text'
                            placeholder="Indoklás..."
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                        <p className='error-message2'>{pinError}</p>
                        <button className="certi-confirm-button" onClick={handleConfirmReject}>
                            Elutasítás
                        </button>
                    </>
                )}
            </Modal>

            <div className="children-content">
                <h1 className='title'>Igazolási kérések</h1>
                <div className='filter-buttons'>
                    <button className={getButtonStyle('pending')} onClick={() => handleFilter('pending')}>Függőben</button>
                    <button className={getButtonStyle('accepted')} onClick={() => handleFilter('accepted')}>Elfogadva</button>
                    <button className={getButtonStyle('rejected')} onClick={() => handleFilter('rejected')}>Elutasítva</button>
                </div>
            </div>
            {filterType === 'pending' && (
                <div className='action-panel'>
                    <button className='action-button2' onClick={handleAcceptSelectedRequests}>Kiválasztott kérelmek elfogadása</button>
                    <button className='action-button2' onClick={handleAcceptAllRequests}>Összes kérelem elfogadása</button>
                </div>
            )}
            {filteredRequests.reverse().map(request => (
                <div className='request-box' key={request.id}>
                    <div className='data-container2'>
                        {request.accepted === null && (
                            <div className='accept-buttons-container'>
                                <input
                                    className='request-chechkbox'
                                    type="checkbox"
                                    checked={selectedRequests.includes(request.id)}
                                    onChange={() => handleSelectRequest(request.id)}
                                />
                                <div>
                                    <button className='close-button' onClick={() => handleRejectRequest(request.id)}>Elutasít</button>
                                    <button className='child-save' onClick={() => handleAcceptRequest(request.id)}>Elfogad</button>
                                </div>

                            </div>
                        )}
                        <div className='name-container2'>
                            <p>{request.childData?.name || 'N/A'}</p>
                        </div>
                        <div className='prev-data2'>
                            <div className='left-data2'>
                                <div className='request-data-names'>
                                    <p>Születési dátum:</p>
                                    <p>Anyja neve:</p>
                                    <p>Oka:</p>
                                </div>
                                <div className='request-datas'>
                                    <p>{request.childData?.birthdate || 'N/A'}</p>
                                    <p>{request.childData?.mothers_name || 'N/A'}</p>
                                    <p>{request.reason}</p>
                                </div>
                            </div>
                            <div className='right-data2'>
                                <div className='request-data-names'>
                                    <p>Kezdete:</p>
                                    <p>Vége:</p>
                                    <p>Közösségbe mehet:</p>
                                </div>
                                <div className='request-datas'>
                                    <p>{request.start_date}</p>
                                    <p>{request.end_date}</p>
                                    <p>{request.can_rejoin_community}</p>
                                </div>
                            </div>
                        </div>
                        <p className='request-date'>
                            {request.accepted === false ? 'Elutasítás dátuma' : 'Kiállítás dátuma'}: {request.issue_date}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CertiRequest;
