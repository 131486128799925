import React, { useState, useEffect } from 'react';
import { getCertificates } from '../api/AllCerti';
import Scrolling from '../components/Scrolling.js';
import '../styles/Statistics.css';
import Uhu from '../assets/Uhu.png'
import AlertModal from '../components/AlertModal.js';
import Chart from 'chart.js/auto';

const Statistics = () => {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const isScrollingDown = Scrolling();

  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        const data = await getCertificates();
        setCertificates(data);
        setLoading(false);
      } catch (error) {
        setError("Hiba történt a szerverrel való kommunikáció közben!");
        setShowErrorModal(true);
        setLoading(false);
      }
    };

    fetchCertificates();
  }, []);

  useEffect(() => {
    if (!loading && certificates.length > 0) {
      renderCharts();
    }
  }, [loading, certificates]);

  const renderCharts = () => {
    renderLineChart();
    renderPieChart();
  };

  const renderLineChart = () => {
    const ctx = document.getElementById('line-chart').getContext('2d');
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const data = certificates.reduce((acc, cert) => {
      const date = new Date(cert.issue_date);
      const month = date.getMonth();
      const monthName = months[month];
      acc[monthName] = (acc[monthName] || 0) + 1;
      return acc;
    }, {});

    const reversedMonths = Object.keys(data).reverse();
    const reversedData = reversedMonths.map(month => data[month]);

    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: reversedMonths,
        datasets: [{
          label: 'Igazolások száma hónapok szerint',
          data: reversedData,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  };

  const renderPieChart = () => {
    const ctx = document.getElementById('pie-chart').getContext('2d');
    const exemptCount = certificates.filter(cert => cert.exempt_from_pe).length;
    const nonExemptCount = certificates.length - exemptCount;

    const chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Felmentett', 'Nem felmentett'],
        datasets: [{
          label: 'Felmentett és Nem felmentett aránya',
          data: [exemptCount, nonExemptCount],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)'
          ]
        }]
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'bottom'
          }
        }
      }
    });
  };

  const averageLength = () => {
    const totalLength = certificates.reduce(
      (total, cert) => total + calculateLength(cert),
      0
    );
    const averageLength = totalLength / totalCertificates;
    return averageLength.toFixed(2);
  };

  const calculateLength = (cert) => {
    if (cert.start_date && cert.end_date) {
      const startDate = new Date(cert.start_date);
      const endDate = new Date(cert.end_date);

      const durationInDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
      return startDate.getTime() === endDate.getTime() ? 1 : durationInDays;
    }
    return 0;
  };

  const calculatePeLength = (cert) => {
    if (cert.start_date && cert.exemption_end_date) {
      const startDate = new Date(cert.start_date);
      const endDate = new Date(cert.exemption_end_date);

      const durationInDays = ((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

      return Math.max(durationInDays, 0);
    }
    return 0;
  };


  const totalCertificates = certificates.length;

  const averageCertificatesPerDay = () => {
    const uniqueDates = Array.from(
      new Set(certificates.map((cert) => {
        const date = new Date(cert.issue_date);
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      }))
    );

    const totalCertificates = certificates.length;

    const average = (totalCertificates / uniqueDates.length).toFixed(2);

    return average;
  };



  const percentExemptFromPE = () => {
    const exemptCount = certificates.filter((cert) => cert.exempt_from_pe).length;
    return ((exemptCount / totalCertificates) * 100).toFixed(2);
  };

  const countMostCertificatesForAnyChild = (certificates) => {
    const counts = {};

    certificates.forEach(cert => {
      const childId = cert.child_id;

      if (childId) {
        counts[childId] = (counts[childId] || 0) + 1;
      }
    });

    const mostCertificatesForChild = Math.max(...Object.values(counts));

    return mostCertificatesForChild;
  };

  const calculateExemptPEAverageLength = (certificates) => {
    const exemptCertificates = certificates.filter(cert => cert.exempt_from_pe && cert.exemption_end_date);

    if (exemptCertificates.length === 0) {
      return 0;
    }

    const totalLength = exemptCertificates.reduce(
      (total, cert) => total + calculatePeLength(cert),
      0
    );

    const averageLength = totalLength / exemptCertificates.length;
    return averageLength.toFixed(2);
  };

  return (
    <div className={`${isScrollingDown ? 'scroll' : ''}`}>

      {loading ? (
        <div className='loading'>
          <img className='uhu' src={Uhu} alt="Loading..." />
        </div>
      ) : (
        <>
          <div className='statistics-container'>
            <h1 className="title3">Statisztikák</h1>
            <div className="statistics-field">
              <div className='stats'>
                <div>
                  <div className='all-certi'>Összes kiállított igazolás:</div>
                  <p>Egy nap átlagosan kiállított igazolások:</p>
                  <p>Igazolások átlagos hossza:</p>
                  <p>Testnevelés alóli felmentések aránya:</p>
                  <p>Egy gyermekhez tartozó legtöbb igazolás:</p>
                  <p>Testnevelés alóli felmentés átlagos hossza:</p>
                </div>
                <div>
                  <div className='all-certi'>{totalCertificates} db</div>
                  <p>{averageCertificatesPerDay()} db</p>
                  <p>{averageLength()} nap</p>
                  <p>{percentExemptFromPE()}%</p>
                  <p>{countMostCertificatesForAnyChild(certificates)} db</p>
                  <p>{calculateExemptPEAverageLength(certificates)} nap</p>
                </div>
              </div>

            </div>
          </div>
          <div className="chart-container">
            <div className="line-chart">
              <canvas id="line-chart"></canvas>
            </div>
            <div className="pie-chart">
              <p className='pie-chart-label'>Testnevelésből felmentettek aránya</p>
              <canvas id="pie-chart"></canvas>
            </div>
          </div>
        </>
      )}
      {error && showErrorModal && <AlertModal message={error} onClose={() => setShowErrorModal(false)} reload={false} />}
    </div>
  );
};

export default Statistics;
