import React from 'react';

const RegistrationPage2 = ({ formData, handleChange, }) => {
  const fieldInfo = {
    clinicPhone: 'A rendelőd telefonszáma.',
    contactEmail: 'A rendelőd email címe.',
    zipCode: 'A rendelőd irányítószáma.',
    city: 'A rendelőd melyik városban található.',
    streetAndNumber: 'A rendelőd milyen címen található.',
    monday: 'A rendelési idő hétfői nyitvatartási ideje.',
    tuesday: 'A rendelési idő kedd nyitvatartási ideje.',
    wednesday: 'A rendelési idő szerda nyitvatartási ideje.',
    thursday: 'A rendelési idő csütörtök nyitvatartási ideje.',
    friday: 'A rendelési idő péntek nyitvatartási ideje.',
    counseling: 'A rendelő csecsemő tanácsadási időpontja.'
  };


  return (
    <>
      <div className='optional-data'>
        <label htmlFor="clinic-data">Rendelő adatai</label>

        <div className='reg-validation-container'>
          <input
            type="text"
            id="clinicPhone"
            name="clinicPhone"
            className='reg-input'
            placeholder='Rendelő Telefonszáma'
            value={formData.clinicPhone}
            onChange={handleChange}
            title={fieldInfo.clinicPhone}
          />
        </div>

        <div className='reg-validation-container'>
          <input
            type="email"
            id="contactEmail"
            name="contactEmail"
            className='reg-input'
            placeholder='Rendelő Email'
            value={formData.contactEmail}
            onChange={handleChange}
            title={fieldInfo.contactEmail}
          />
        </div>

        <div className='reg-validation-container'>
          <input
            type="text"
            id="zipCode"
            name="zipCode"
            className='reg-input'
            placeholder='IrányÍtószám'
            value={formData.zipCode}
            onChange={handleChange}
            title={fieldInfo.zipCode}
          />
        </div>

        <div className='reg-validation-container'>
          <input
            type="text"
            id="city"
            name="city"
            className='reg-input'
            placeholder='Város'
            value={formData.city}
            onChange={handleChange}
            title={fieldInfo.city}
          />
        </div>

        <div className='reg-validation-container'>
          <input
            type="text"
            id="streetAndNumber"
            name="streetAndNumber"
            className='reg-input'
            placeholder='Utca, házszám'
            value={formData.streetAndNumber}
            onChange={handleChange}
            title={fieldInfo.streetAndNumber}
          />
        </div>

        <div className='reg-validation-container'>
          <label htmlFor="hours" title="Add meg a rendelési időpontokat.">Rendelési idő</label>
          <input
            type="text"
            id="monday"
            name="monday"
            className='reg-input'
            placeholder='Hétfő'
            value={formData.monday}
            onChange={handleChange}
            title={fieldInfo.monday}
          />
        </div>

        <div className='reg-validation-container'>
          <input
            type="text"
            id="tuesday"
            name="tuesday"
            className='reg-input'
            placeholder='Kedd'
            value={formData.tuesday}
            onChange={handleChange}
            title={fieldInfo.tuesday}
          />
        </div>

        <div className='reg-validation-container'>
          <input
            type="text"
            id="wednesday"
            name="wednesday"
            className='reg-input'
            placeholder='Szerda'
            value={formData.wednesday}
            onChange={handleChange}
            title={fieldInfo.wednesday}
          />
        </div>

        <div className='reg-validation-container'>
          <input
            type="text"
            id="thursday"
            name="thursday"
            className='reg-input'
            placeholder='Csütörtök'
            value={formData.thursday}
            onChange={handleChange}
            title={fieldInfo.thursday}
          />
        </div>

        <div className='reg-validation-container'>
          <input
            type="text"
            id="friday"
            name="friday"
            className='reg-input'
            placeholder='Péntek'
            value={formData.friday}
            onChange={handleChange}
            title={fieldInfo.friday}
          />
        </div>

        <div className='reg-validation-container'>
          <input
            type="text"
            id="counseling"
            name="counseling"
            className='reg-input'
            placeholder='Csecsemő tanácsadás'
            value={formData.counseling}
            onChange={handleChange}
            title={fieldInfo.counseling}
          />
        </div>
      </div>
    </>
  );

}

export default RegistrationPage2;
