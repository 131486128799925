import API_BASE_URL from './ApiConfig';

export async function fetchCsrfToken() {
  try {
    const response = await fetch(`${API_BASE_URL}/api/csrf_token`, {
      method: 'GET',
      credentials: 'include'
    });
    const data = await response.json();
    let csrfToken = data.csrf_token;
    return csrfToken;
  } catch (error) {
    return null;
  }
}

export async function addCsrfTokenToRequests(requestConfig) {
  const csrfToken = await fetchCsrfToken();
  if (csrfToken) {
    requestConfig.headers = {
      ...requestConfig.headers,
      'X-CSRFToken': csrfToken
    };
  }
  return requestConfig;
}

export async function fetchWithCsrf(url, config) {
  const requestConfig = await addCsrfTokenToRequests(config || {});
  requestConfig.credentials = 'include'; // Add credentials: 'include' to the request
  return fetch(url, requestConfig);
}
