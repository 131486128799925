import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchDoctorData } from '../api/DoctorId';
import profilePic from '../assets/profilepic.png';
import ProfileMenu from './ProfileMenu';
import '../styles/Header.css';
import NameLogo from '../assets/NameLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';



function Header({ onLogout }) {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [doctorData, setDoctorData] = useState(null)
  const [error, setError] = useState(null);

  useEffect(() => {
    const doctorId = parseInt(sessionStorage.getItem('doctorId'), 10);
    const fetchData = async () => {
      try {
        const data = await fetchDoctorData(doctorId);
        setDoctorData(data.doctor);
      } catch (err) {
        setError(err);
      }
    };

    fetchData();
  }, []);

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const closeProfileMenu = () => {
    setIsProfileMenuOpen(false);
  };

  return (
    <div>
      <header className="header">
        <img className="header-logo" src={NameLogo} alt="Dooki Logo" />
        <div className="profile-container">
          <div className="profilepic-container">
            <img
              className="profilepic"
              src={profilePic}
              alt="Profilkép"
              onClick={toggleProfileMenu}
            />
            {isProfileMenuOpen && <ProfileMenu onClose={closeProfileMenu} onLogout={onLogout} />}
          </div>
          <div className="name">{doctorData && doctorData.name}</div>
        </div>
        <div>

          <Link className='help-link' to="/virtualis-tudasbazis" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className='download-icon' icon={faQuestion} />
            Segítség
          </Link>

        </div>
      </header>
    </div>
  );
}

export default Header;