import API_BASE_URL from './ApiConfig';

const checkCertificate = async (uuid) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/certificates/check/${uuid}`);

        if (!response.ok) {
            throw new Error(response.statusText || 'Failed to fetch data');
        }

        const certificateData = await response.json();
        return certificateData;
    } catch (error) {
        throw error;
    }
};

export { checkCertificate };
