import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { checkCertificate } from '../api/CheckCerti';
import NameLogo from '../assets/NameLogo.png';
import Shield from '../assets/Shield.png';
import Success from '../assets/Success.png';
import Fail from '../assets/Fail.png';
import AlertModal from '../components/AlertModal.js';
import '../styles/CheckCerti.css';

const CertiCheck = () => {
    const { token } = useParams();
    const [certificateId, setCertificateId] = useState(token ? token : '');
    const [result, setResult] = useState(null);
    const [verificationResult, setVerificationResult] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [idError, setIdError] = useState(null);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [error, setError] = useState(null);

    const handleVerification = () => {
        if (certificateId.trim() === '') {
            setIdError('Az igazolás egyedi azonosítóját kötelező megadni.');
            return;
        } else if (certificateId.length !== 10) {
            setIdError('Az igazolás egyedi azonosítójának 10 karakter hosszúnak kell lennie.');
            return;
        }

        checkCertificate(certificateId)
            .then((data) => {
                const isOneDayCertificate = data.start_date === data.end_date;

                setResult({
                    ...data,
                    is_one_day_certificate: isOneDayCertificate,
                });

                const issuedDate = new Date(data.issue_date);
                const sixMonthsAgo = new Date(new Date() - 6 * 30 * 24 * 60 * 60 * 1000);
                setVerificationResult(issuedDate > sixMonthsAgo);

                setShowResult(true);
            })
            .catch((error) => {
                if (error.message === 'NOT FOUND') {
                    setShowResult(true);
                } else {
                    setError("Hiba történt a szerverrel való kommunikáció közben!");
                    setShowAlertModal(true);
                }
                setVerificationResult(false);
            });
    };

    return (
        <div className='check-certi-container'>
            <img className='name-logo' src={NameLogo} alt="DookiLogo" />

            <p>Ezen az oldalon tudod ellenőrizni az igazolás hitelességét.</p>
            <p>A Dooki rendszerében tárolt igazolások ellenőrzésére az igazolás kiállításától számított 6 hónapig van lehetőség. Ennél korábbi igazolás hitelességét a kiállító orvos tudja megerősíteni.</p>

            <img className='shield' src={Shield} alt="Shield" />

            <label >Az igazolás egyedi azonosítója</label>
            <div className='field-picker'>
                <input
                    type="text"
                    placeholder="Add meg az igazolás egyedi azonosítóját"
                    value={certificateId}
                    onChange={(e) => {
                        setCertificateId(e.target.value);
                        setIdError(null);
                    }}
                    className={idError ? "error-picker" : "date-picker"}
                />
                {idError && <p className='error-message'>{idError}</p>}
            </div>

            {result && verificationResult === true &&
                <>
                    <p>Születési dátum: ****-{result.birthdate}</p>
                    <p>Igazolás kezdőnapja: {result.start_date}</p>

                    {result.is_one_day_certificate ? (
                        <p>Egynapos igazolás</p>
                    ) : (
                        <p>Igazolás utolsó napja: {result.end_date}</p>
                    )}
                </>
            }

            <button className='reg-button' onClick={handleVerification}>Igazolás ellenőrzése</button>

            {showResult && verificationResult === false ? (
                <div className='verification-message'>
                    <img className='verification' src={Fail} alt="fail" />
                    <p>Sajnáljuk, ilyen adattartalmú igazolást nem állított ki orvos a Dooki rendszerében.</p>
                    <p>Kérjük ellenőrizd a megadott adatokat és eltérés esetén próbáld újra az ellenőrzést.</p>
                </div>
            ) : showResult && verificationResult === true ? (
                <div className='verification-message'>
                    <img className='verification' src={Success} alt="success" />
                    <p>Jó hírünk van, az igazolást a Dooki rendszeréből állították ki.</p>
                    <p>A megadott adattartalom megegyezik az orvos által megadott adatokkal.</p>
                </div>
            ) : ''}
        {error && showAlertModal && <AlertModal message={error} onClose={() => setShowAlertModal(false)} reload={false} />}
        </div>
    );
};

export default CertiCheck;
