import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { getChildren } from '../api/AllChildren';
import { getChildrenPages } from '../api/AllChildrenPages';
import ChildDataImport from '../api/ChildDataImport.js';
import { handleChildDeletion } from '../api/DeleteChild.js';
import AddChild from '../api/AddChild.js';
import UpdateChild from '../api/UpdateChild.js'
import AddChildModal from '../components/AddChildModal.js';
import FileUploadModal from '../components/DataImportModal.js';
import FilterControls from '../components/Filter.js';
import ChildCard from '../components/ChildCard.js';
import AlertModal from '../components/AlertModal.js';
import ImportAlert from '../components/ImportAlert.js';
import ConfirmModal from '../components/ConfirmModal.js';
import '../styles/Children.css'
import Uhu from "../assets/Uhu.png"

const Children = () => {
    const [children, setChildren] = useState([]);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showAlertModal2, setShowAlertModal2] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const maxDate = new Date();
    const doctorId = parseInt(sessionStorage.getItem('doctorId'), 10);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState(null);
    const [templateErrors, setTemplateErrors] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editingChild, setEditingChild] = useState(null);
    const [isFileUploadModalVisible, setIsFileUploadModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [deletingChildId, setDeletingChildId] = useState(null);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState(null);
    const [selectedPage, setSelectedPage] = useState(1);
    const [sortingOption, setSortingOption] = useState('child_name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [isSorting, setIsSorting] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        birthdate: '',
        birthplace: '',
        address: '',
        mothers_name: '',
        parent_email: '',
    });

    const [filter, setFilter] = useState({
        nameAndTajFilter: '',
        birthdate: null,
    });

    const formatDate = (date) => {
        return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0] : null;
    };

    const handleDateChange = (date) => {
        handleFilterChange({
            target: {
                name: 'birthdate',
                value: formatDate(date),
            },
        });
    };

    const sortingOptions = [
        { value: 'child_name', label: 'Név' },
        { value: 'birthdate', label: 'Születési dátum' }
    ];

    const handleSortButtonClick = () => {
        setIsSorting(!isSorting);
    };

    const handleSortingOptionChange = (event) => {
        setSortingOption(event.target.value);
    };

    const handleSortOrderChange = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const handleAddChildClick = () => {
        setFormData(initialChildState);
        setSelectedDate(null);
        setIsEditing(false);
        setShowModal(true);
    };

    useEffect(() => {
        const fetchChildrenData = async () => {
            try {
                const response = await getChildrenPages(page, sortingOption, sortOrder);
                setLoading(false);
                setChildren(response.children);
                setPagination(response.pagination);
            } catch (error) {
                setError("Hiba történt a szerverrel való kommunikáció közben!");
                setShowErrorModal(true);
                setLoading(false);
            }
        };

        if (filter.nameAndTajFilter === '' && filter.birthdate === null) {
            fetchChildrenData();
        }

    }, [page, filter, sortingOption, sortOrder]);

    useEffect(() => {
        let timer;

        const fetchChildrenData = async () => {
            try {
                const response = await getChildren();
                const filteredChildren = filterChildren(response);
                setChildren(filteredChildren);
            } catch (error) {
                setError("Hiba történt a szerverrel való kommunikáció közben!");
                setShowErrorModal(true);
            }
        };

        const filterChildren = (children) => {
            return children.filter(child => {
                if (filter.nameAndTajFilter && !child.name.toLowerCase().includes(filter.nameAndTajFilter.toLowerCase())) {
                    return false;
                }
                if (filter.birthdate && child.birthdate !== filter.birthdate) {
                    return false;
                }
                return true;
            });
        };

        const handleSearch = () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                if (filter.nameAndTajFilter !== '' || filter.birthdate !== null) {
                    fetchChildrenData();
                }
            }, 300);
        };

        handleSearch();

        return () => clearTimeout(timer);
    }, [filter]);


    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter((prevFilter) => ({
            ...prevFilter,
            [name]: value,
        }));

        if (name === 'nameAndTajFilter' || name === 'birthdate') {
            window.scrollTo({ top: 0, behavior: 'instant' });
        }
    };

    const handleAdatimportClick = () => {
        setIsFileUploadModalVisible(true);
    };

    const handleCloseFileUploadModal = () => {
        setIsFileUploadModalVisible(false);
    };

    const initialChildState = {
        name: '',
        birthdate: '',
        birthplace: '',
        address: '',
        mothers_name: '',
        //taj: '',
        parent_email: '',
    };

    const handlePageChange = async (newPage) => {
        try {
          if (newPage !== page) {
            setPage(newPage);
            setSelectedPage(newPage);
            setLoading(true);
          }
        } catch (error) {
          console.error('Error fetching certificates:');
        }
      };  

    const handleSaveClick = async () => {
        try {
            const dataToBeSent = {
                ...formData,
                doctor_id: doctorId,
                birthdate: selectedDate.toDateString(),
            };

            const response = isEditing
                ? await UpdateChild.updateChild(editingChild.id, dataToBeSent)
                : await AddChild.addChild(dataToBeSent);

            if (response.success) {
                setShowAlertModal(true);
                setShowModal(false);
                return { success: true };
            } else {
                return { success: false, error: response.error };
            }
        } catch (error) {
            setError("Hiba történt a szerverrel való kommunikáció közben!");
            setShowErrorModal(true);
            throw error;
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };


    const handleFileUpload = async (event) => {
        event.preventDefault();

        try {
            const response = await ChildDataImport.addChildren(file);

            if (response.success) {
                if (response.errors) {
                    setMessage(response.success);
                    setTemplateErrors(response.errors);
                } else {
                    setMessage("Az adatok beolvasása sikeres.");
                }

                setShowAlertModal2(true);
                setIsFileUploadModalVisible(false);
            }
        } catch (error) {
            setShowAlertModal2(true);
            setIsFileUploadModalVisible(false);
            setMessage("Az adatok beolvasása sikertelen volt.");
        }
    };

    const handleDeleteClick = (childId) => {
        setDeletingChildId(childId);
        setShowConfirmModal(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await handleChildDeletion(deletingChildId);
            setDeletingChildId(null);
            setShowConfirmModal(false);
            window.location.reload();
        } catch (error) {
            setError("Hiba történt a szerverrel való kommunikáció közben!");
            setShowErrorModal(true);
        }
    };


    const handleEditClick = (child) => {
        setFormData({
            name: child.name,
            birthdate: new Date(child.birthdate),
            birthplace: child.birthplace,
            address: child.address,
            mothers_name: child.mothers_name,
            //taj: child.taj,
            parent_email: child.parent_email,
        });

        setSelectedDate(new Date(child.birthdate));
        setEditingChild(child);
        setIsEditing(true);
        setShowModal(true);
    };

    return (
        <div className="children-data-container">
            {loading ? (
                <img className='uhu' src={Uhu} alt="Loading..." />
            ) : (
                <>
                    {error && showErrorModal && <AlertModal message={error} onClose={() => setShowErrorModal(false)} reload={false} />}
                    {showAlertModal && <AlertModal message={"Gyermek adatai sikeresen mentve"} onClose={() => setShowAlertModal(false)} reload={true} />}
                    {showAlertModal2 && <ImportAlert message={message} errors={templateErrors} onClose={() => setShowAlertModal(false)} reload={true} />}
                    {showConfirmModal && (
                        <ConfirmModal
                            message={"Biztosan törölni szeretné?"}
                            onClose={() => setShowAlertModal(false)}
                            onConfirm={handleConfirmDelete}
                            reload={false}
                        />
                    )}

                    <AddChildModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        maxDate={maxDate}
                        formData={formData}
                        handleChange={handleChange}
                        handleSaveClick={handleSaveClick}
                    />

                    <div className="children-content">
                        <div className='title-sort'>
                            <h1 className='title'>Gyermekek</h1>
                            <FilterControls filter={filter} handleFilterChange={handleFilterChange} handleDateChange={handleDateChange} />
                        </div>
                    </div>
                    <div className="children-container">
                        <div className='sorting-container'>
                            <button className='sort-button' onClick={handleSortButtonClick}>
                                Rendezés
                            </button>
                            {isSorting && (
                                <div className="sort-options">
                                    <select name="field" value={sortingOption} onChange={handleSortingOptionChange}>
                                        {sortingOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <button onClick={handleSortOrderChange}>
                                        {sortOrder === 'asc' ? '▲' : '▼'}
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className='table'>
                            <div className="header2">
                                <div className="header2-cell">Név</div>
                                <div className="header2-cell">Születési dátum</div>
                                <div className="header2-cell">Születési hely</div>
                                <div className="header2-cell">Anyja neve</div>
                                <div className="header2-cell">Műveletek</div>
                            </div>
                            <div className='table-data'>
                                {children.map((child) => (
                                    <ChildCard
                                        key={child.id}
                                        child={child}
                                        handleEditClick={handleEditClick}
                                        handleDeleteClick={handleDeleteClick}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    {filter.nameAndTajFilter === '' && filter.birthdate === null && pagination && (
                <div className="pagination-children">
                    {Array.from({ length: pagination.total_pages }, (_, index) => (
                        <button
                            className={`page-select-button ${selectedPage === index + 1 ? 'selected-page' : ''}`}
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
                </>
            )}
            <div className='action-panel'>
                <div className='add-child-options'>
                    <button className='add-child' onClick={handleAddChildClick}>Gyermek hozzáadása</button>
                    <button className='add-child' onClick={handleAdatimportClick}>
                        Gyermekek adatainak importálása
                    </button>
                    <FileUploadModal
                        isOpen={isFileUploadModalVisible}
                        handleCloseFileUploadModal={handleCloseFileUploadModal}
                        handleFileUpload={handleFileUpload}
                        handleFileChange={handleFileChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default Children;