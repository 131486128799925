import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowDown } from '@fortawesome/free-solid-svg-icons';
import downloadTemplate from '../api/Template';
import AlertModal from '../components/AlertModal.js';

const FileUploadModal = ({ isOpen, handleCloseFileUploadModal, handleFileUpload, handleFileChange }) => {
  const [error, setError] = useState(null);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const handleDownloadTemplate = async () => {
    const success = await downloadTemplate();
    if (!success) {
      setError('Hiba a letöltés közben!');
      setShowAlertModal(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseFileUploadModal}
      contentLabel="Fájl feltöltés"
      className='add-child-modal'
    >
      <button className='download-template-button' onClick={handleDownloadTemplate}>
      <FontAwesomeIcon className='download-icon' icon={faArrowDown}/>
       Sablon fájl letöltése
      </button>
      <form onSubmit={handleFileUpload}>
        <p>Válasszd ki a feltölteni kívánt fájlt!</p>
        <input className="custom-file-input-button" type="file" accept=".xlsx" onChange={handleFileChange} />
        <div className='upload-buttons'>
          <button className='upload' type="submit">Feltöltés</button>
          <button className='close-button' onClick={handleCloseFileUploadModal}>
            Mégsem
          </button>
        </div>
      </form>
      {error && showAlertModal && <AlertModal message={error} onClose={() => setShowAlertModal(false)} reload={false} />}
    </Modal>
  );
}

export default FileUploadModal;
