import API_BASE_URL from './ApiConfig';

export const exportDoctorData = async (doctorId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/export_doctor_data/${doctorId}`, {
            method: 'GET',
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error('Failed to export doctor data');
        }

        const blob = await response.blob();

        if (!blob) {
            throw new Error('Failed to download doctor data');
        }

        return blob;
    } catch (error) {
        throw error;
    }
};