import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import loginApi from '../api/LoginApi';
import { handleForgotPassword } from '../api/ForgotPassword';
import '../styles/Login.css'
import NameLogo from '../assets/NameLogo.png'
import LoginPic from '../assets/LoginPic.png'
import JogiNyil from '../assets/Jogi_nyilatkozat_dooki.pdf'

const Login = ({ onAuthentication }) => {
  const [formData, setFormData] = useState({
    LoginEmail: '',
    password: '',
  });
  const [forgotPassword, setForgotPassword] = useState('');
  const [loginDisabled, setLoginDisabled] = useState(false);
  const [loginError, setLoginError] = useState('');
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [wrongEmail, setWrongEmail] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isModalOpen) {
      try {
        const result = await loginApi(formData.LoginEmail.toLowerCase(), formData.password);

        if (result.success) {
          onAuthentication(true);
          history.push('/');
        } else {
          if (result.error) {
            setLoginError(result.error);
          }
        }
      } catch (error) {
        console.error('Hiba a bejelentkezés során:', error);
      }
    }
  };

  const handleSendPasswordRecovery = async () => {
    try {
      const result = await handleForgotPassword(forgotPassword);

      if (result.success) {
        setShowSuccessMessage(true);
      } else {
        setWrongEmail(true);
      }
    } catch (error) {
      console.error('Hiba a jelszó visszaállítás során:', error);
    }
  };

  const handleForgotPasswordClick = () => {
    setIsModalOpen(true);
    setLoginDisabled(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLoginDisabled(false);
    setShowSuccessMessage(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <div className="login-container">
      <div>
        <img className='login-pic' src={LoginPic} alt="Login" />

      </div>
      <div className='login-fields'>
        <div className='login-field-container'>
          <img className='name-logo' src={NameLogo} alt="DookiLogo" />
          <p className='welcome' >Üdvözlünk újra!</p>
          <p>Jó, hogy itt vagy.</p>
          <form className="login-form" onSubmit={handleSubmit}>
            <h1 className="title2">Bejelentkezés</h1>
            <label className="login-label">
              <input
                type="email"
                name="LoginEmail"
                value={formData.LoginEmail}
                onChange={handleChange}
                className="login-input"
                placeholder="Email"
                disabled={loginDisabled}
              />
            </label>
            <br />
            <label className="login-label">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="login-input"
                placeholder="Jelszó"
                disabled={loginDisabled}
              />
            </label>
            <br />
            <button type="submit" className="login-button" disabled={loginDisabled}>
              Bejelentkezés
            </button>
            {loginError && <p className="login-error">{loginError}</p>}
            <div className='forgot-password-container'>
              <p>Elfelejtetted a jelszavadat?</p>
              <button className="forgot-password" onClick={handleForgotPasswordClick}>
                Itt tudsz új jelszót kérni
              </button>
            </div>
            <p className="login-link">
              Nincs még orvosi fiókod? <Link className='registration-link' to="/registration">Itt tudsz regisztrálni</Link>
            </p>
          </form>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            contentLabel="Forgot Password Modal"
            className='forgot-password-modal'
          >
            <h1 className='title2'>Elfelejtett jelszó</h1>
            {showSuccessMessage ? (
              <div className='success-message'>
                <p>A jelszó módosításához szükséges linket elküldtük az e-mail címedre!</p>
                <button className='save-button' onClick={handleCloseModal}>Rendben</button>
              </div>
            ) : (
              <div>
                <p>Kérjük adja meg az e-mail címét</p>
                <input
                  type="email"
                  placeholder="Email"
                  value={forgotPassword}
                  className='date-picker'
                  onChange={(e) => setForgotPassword(e.target.value)}
                />
                {wrongEmail && <p className="login-error">Nem megfelelő email címet adtál meg!</p>}
                <div>
                  <button className='send' onClick={handleSendPasswordRecovery}>Email küldése</button>
                  <button className='close-button' onClick={handleCloseModal}>Bezárás</button>
                </div>
              </div>
            )}
          </Modal>
        </div>
        <button className='impressum-button' onClick={handleOpenModal}>Impresszum</button>
        {showModal && (
        <div className="cookie-pdf-modal">
          <div className="cookie-pdf-modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <iframe title="DookiCookie" src={JogiNyil} style={{ width: '100%', height: '80vh' }} />
          </div>
        </div>
      )}

      </div>
    </div>
  );
};

export default Login;