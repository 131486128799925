import React, { useState } from 'react';
import changePassword from '../api/ChangePassword.js';
import changePin from '../api/ChangePin.js'
import AlertModal from '../components/AlertModal.js';
import PinReminderModal from './PinReminderModal.js';

const SettingsFields = ({ doctorData, handleInputChange, handleClinicAddressChange, handleOfficeHoursChange, setIsOfficeHoursOpen, isOfficeHoursOpen, setNewFile, isFileUploadDisabled, errors }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [currentPin, setCurrentPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [confirmNewPin, setConfirmNewPin] = useState('');
  const [showChangePasswordModal, setShowChangePasswordModal] = useState('');
  const [showChangePinModal, setShowChangePinModal] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [pinError, setPinError] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showAlertModal2, setShowAlertModal2] = useState(false);
  const [error, setError] = useState(null);
  const [showPinReminderModal, setShowPinReminderModal] = useState(false);

  const handleChangePassword = async () => {
    try {
      const result = await changePassword(doctorData.id, currentPassword, newPassword);

      if (result.success) {
        setShowAlertModal(true);
        setShowChangePasswordModal(false);
        setPasswordError('');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      } else {
        setPasswordError('Az aktuális jelszó helytelen.');
      }
    } catch (error) {
      setError("Hiba történt a szerverrel való kommunikáció közben!");
      setShowAlertModal2(true);
    }
  };

  const handleChangePin = async () => {
    try {
      const result = await changePin(doctorData.id, currentPin, newPin);

      if (result.success) {
        setShowAlertModal(true);
        setShowChangePinModal(false);
        setPinError('');
        setCurrentPin('');
        setNewPin('');
        setConfirmNewPin('');
      } else {
        setPinError('Az aktuális PIN helytelen.');
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleSavePassword = () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setPasswordError('Minden mezőt ki kell tölteni.');
      return;
    }
    if (newPassword.length < 8) {
      setPasswordError('A jelszónak legalább 8 karakter hosszúnak kell lennie.');
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setPasswordError('Az új jelszó nem egyezik meg az új jelszó megerősítésével!');
      return;
    }
    handleChangePassword();
  };

  const handleSavePin = () => {
    if (!currentPin || !newPin || !confirmNewPin) {
      setPinError('Minden mezőt ki kell tölteni.');
      return;
    }
    if (newPin.length < 6) {
      setPinError('A PIN-nek legalább 6 karakter hosszúnak kell lennie.');
      return;
    }
    if (newPin !== confirmNewPin) {
      setPinError('Az új PIN mezőnek és az új PIN mezőknek egyezniük kell!');
      return;
    }
    handleChangePin();
  };

  const handlePinReminder = () => {
    setShowPinReminderModal(true);
  };

  return (
    <>

      <div className='settings-label'>Orvos Adatai</div>
      <div className="field-picker">
        <label>Név</label>
        <input
          type="text"
          value={doctorData.name}
          onChange={(e) => handleInputChange('name', e.target.value)}
          className={errors.name ? "error-picker" : "date-picker"}
          required
        />
        {errors.name && <div className="error-message">{errors.name}</div>}
      </div>
      <div className="field-picker">
        <label>Email</label>
        <input
          type="text"
          value={doctorData.login_email}
          onChange={(e) => handleInputChange('login_email', e.target.value)}
          className={errors.login_email ? "error-picker" : "date-picker"}
          required
        />
        {errors.login_email && <div className="error-message">{errors.login_email}</div>}
      </div>
      <div className="field-picker">
        <label>Orvosi nyilvántartási szám (pecsétszám)</label>
        <input
          type="text"
          value={doctorData.registration_number}
          onChange={(e) => handleInputChange('registration_number', e.target.value)}
          className={errors.registration_number ? "error-picker" : "date-picker"}
          required
        />
        {errors.registration_number && <div className="error-message">{errors.registration_number}</div>}
      </div>
      <div className="field-picker">
        <label>Kapcsolattartási telefonszám</label>
        <input
          type="text"
          value={doctorData.contact_phone}
          onChange={(e) => handleInputChange('contact_phone', e.target.value)}
          className={errors.contact_phone ? "error-picker" : "date-picker"}
          required
        />
        {errors.contact_phone && <div className="error-message">{errors.contact_phone}</div>}
      </div>
      <div className='settings-label'>Rendelő Adatai</div>
      <div className="field-picker">
        <label>Város</label>
        <input
          type="text"
          value={doctorData.clinic_address.city}
          onChange={(e) => handleClinicAddressChange('city', e.target.value)}
          className='date-picker'
        />
      </div>
      <div className="field-picker">
        <label>Utca és házszám</label>
        <input
          type="text"
          value={doctorData.clinic_address.streetAndNumber}
          onChange={(e) => handleClinicAddressChange('streetAndNumber', e.target.value)}
          className='date-picker'
        />
      </div>
      <div className="field-picker">
        <label>Irányítószám</label>
        <input
          type="text"
          value={doctorData.clinic_address.zipCode}
          onChange={(e) => handleClinicAddressChange('zipCode', e.target.value)}
          className='date-picker'
        />
      </div>
      <div className="field-picker">
        <label>Rendelő telefonszáma</label>
        <input
          type="text"
          value={doctorData.clinic_phone}
          onChange={(e) => handleInputChange('clinic_phone', e.target.value)}
          className='date-picker'
        />
      </div>
      <div className="field-picker">
        <label>Rendelő Email</label>
        <input
          type="text"
          value={doctorData.contact_email}
          onChange={(e) => handleInputChange('contact_email', e.target.value)}
          className='date-picker'
        />
      </div>
      <div className='settings-label'>Rendelési idő</div>
      <div className="clinic-data">
        <div className="arrow-header" onClick={() => setIsOfficeHoursOpen(!isOfficeHoursOpen)}>
          <div>Rendelési idő részletei</div>
          <div className="arrow-icon">{isOfficeHoursOpen ? '▼' : '▶'}</div>
        </div>
        {isOfficeHoursOpen && (
          <>
            {['Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Csecsemő tanácsadás'].map(day => (
              <React.Fragment key={day}>
                <div className="field-picker">
                  <label>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                  <input
                    type="text"
                    value={doctorData.office_hours[day]}
                    onChange={(e) => handleOfficeHoursChange(day, e.target.value)}
                    className='date-picker'
                  />
                </div>
              </React.Fragment>
            ))}
          </>
        )}
      </div>

      <div className='spec-container'>
        <p>Specializációk</p>
        <div className='specialization'>
          <p>Alapbeállítás: Házi gyermekorvos</p>
          <p>Adjon meg további specializációt:</p>
        </div>
      </div>

      <input
        type="text"
        className='date-picker'
        placeholder='Specializáció'
        value={doctorData.specialization}
        onChange={(e) => handleInputChange('specialization', e.target.value)}
      />

      {isFileUploadDisabled ? (
        null
      ) : (
        <>
          <label>Orvosi azonosító lap feltöltése:</label>
          <input
            type="file"
            accept=".pdf"
            onChange={(e) => setNewFile(e.target.files[0])}
          />
        </>
      )}

      <button className='change-button' onClick={() => setShowChangePasswordModal(true)}>Jelszó megváltoztatása</button>
      {showChangePasswordModal && (
        <div className="modal">
          <div className="modal-content">
            <h2 className='title2'>Jelszó megváltoztatása</h2>
            <input
              type="password"
              placeholder="Korábbi jelszó"
              className='modal-input'
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Új jelszó"
              className='modal-input'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Új jelszó megerősítése"
              className='modal-input'
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            {passwordError && <div className="error-message">{passwordError}</div>}
            <div>
              <button className='child-save' onClick={handleSavePassword}>Mentés</button>
              <button className='close-button' onClick={() => setShowChangePasswordModal(false)}>Bezárás</button>
            </div>
          </div>
        </div>
      )}


      <button className='change-button' onClick={() => setShowChangePinModal(true)}>PIN megváltoztatása</button>
      {showChangePinModal && (
        <div className="modal">
          <div className="modal-content">
            <h2 className='title2'>PIN megváltoztatása</h2>
            <input
              type="password"
              placeholder="Jelenlegi PIN"
              className='modal-input'
              value={currentPin}
              onChange={(e) => setCurrentPin(e.target.value)}
            />
            <input
              type="password"
              placeholder="Új PIN"
              className='modal-input'
              value={newPin}
              onChange={(e) => setNewPin(e.target.value)}
            />
            <input
              type="password"
              placeholder="Új PIN megerősítése"
              className='modal-input'
              value={confirmNewPin}
              onChange={(e) => setConfirmNewPin(e.target.value)}
            />
            <button className='forgot-pin-button' onClick={handlePinReminder}>Elfelejtett Pin kód</button>
            {showPinReminderModal && <PinReminderModal doctorId={doctorData.id} onClose={() => setShowPinReminderModal(false)} />}

            {pinError && <div className="error-message">{pinError}</div>}
            <div>
              <button className='child-save' onClick={handleSavePin}>Mentés</button>
              <button className='close-button' onClick={() => setShowChangePinModal(false)}>Bezárás</button>
            </div>
          </div>
        </div>
      )}

      <div className='settings-checkbox'>
        <input
          type="checkbox"
          checked={doctorData.subscribe_newsletter}
          onChange={(e) => handleInputChange('subscribe_newsletter', e.target.checked)}
        />
        <label>Feliratkozás a hírlevélre</label>
      </div>
      {error && showAlertModal2 && <AlertModal message={error} onClose={() => setShowAlertModal(false)} reload={false} />}
      {showAlertModal && <AlertModal message={"Adatok sikeresen mentve"} onClose={() => setShowAlertModal(false)} reload={false} />}
    </>
  );
}

export default SettingsFields;
