import API_BASE_URL from './ApiConfig';

const UpdateChild = {
  updateChild: async (childId, formData) => {
    console.log(formData);
    try {
      const formDataObject = new FormData();
      Object.keys(formData).forEach(key => {
        formDataObject.append(key, formData[key]);
      });

      const response = await fetch(`${API_BASE_URL}/api/children/${childId}`, {
        method: 'PUT',
        body: formDataObject,
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        return { success: true, message: data.message };
      } else {
        const errorData = await response.json();
        return { success: false, error: errorData.error };
      }
    } catch (error) {
      return { success: false, error: 'Error during API request.' };
    }
  },
};

export default UpdateChild;
