import React, { useState, useEffect } from 'react';
import '../styles/Previous.css';
import { getCertiPages } from '../api/AllCertiPages.js';
import { getCertificates } from '../api/AllCerti.js';
import { getChildDetails } from '../api/ChildId';
import CertificateDetailsPage from '../components/CertificateDetailsPage';
import CertificateFilter from '../components/CertificateFilter';
import Uhu from '../assets/Uhu.png';
import AlertModal from '../components/AlertModal.js';

const Previous = () => {
  const [certificates, setCertificates] = useState([]);
  const [childDetailsMap, setChildDetailsMap] = useState({});
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [error, setError] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortingOption, setSortingOption] = useState('issue_date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [isSorting, setIsSorting] = useState(false);

  const sortingOptions = [
    { value: 'issue_date', label: 'Kiállítás dátuma' },
    { value: 'child_name', label: 'Név' },
    { value: 'child_birthdate', label: 'Születési dátum' }
  ];

  const handleSortButtonClick = () => {
    setIsSorting(!isSorting);
  };

  const handleSortingOptionChange = (event) => {
    setSortingOption(event.target.value);
  };

  const handleSortOrderChange = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    const fetchData = async () => {

      try {
        const apiData = await getCertiPages(page, sortingOption, sortOrder);
        setCertificates(apiData.certificates);
        setPagination(apiData.pagination);

        const newChildDetailsMap = {};
        await Promise.all(
          apiData.certificates.map(async (certificate) => {
            if (!newChildDetailsMap[certificate.child_id]) {
              const details = await getChildDetails(certificate.child_id);
              newChildDetailsMap[certificate.child_id] = details;
            }
          })
        );

        setChildDetailsMap(newChildDetailsMap);
        setLoading(false);
      } catch (error) {
        setError("Hiba történt a szerverrel való kommunikáció közben!");
        setShowErrorModal(true);
        setLoading(false);
      }
    };

    if (searchTerm === '' && startDate === null) {
      fetchData();
    }
  }, [page, searchTerm, startDate, sortingOption, sortOrder]);

  useEffect(() => {
    let timer;

    const fetchData = async () => {
      try {
        const apiData = await getCertificates();
        setCertificates(apiData);
        const childDetailsMap = {};

        const uniqueChildIds = [...new Set(apiData.map(certificate => certificate.child_id))];
        await Promise.all(
          uniqueChildIds.map(async (childId) => {
            const details = await getChildDetails(childId);
            childDetailsMap[childId] = details;
          })
        );

        setChildDetailsMap(childDetailsMap);
      } catch (error) {
        setError("Hiba történt a szerverrel való kommunikáció közben!");
        setShowErrorModal(true);
      }
    };

    const handleSearch = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fetchData();
      }, 300);
    };

    if (searchTerm.length > 0) {
      handleSearch();
    }

    if (startDate) {
      fetchData();
    }

    return () => clearTimeout(timer);
  }, [searchTerm, startDate]);

  const handleCertificateClick = (certificate) => {
    setSelectedCertificate(certificate);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handlePageChange = async (newPage) => {
    try {
      if (newPage !== page) {
        setPage(newPage);
        setSelectedPage(newPage);
        setLoading(true);
      }
    } catch (error) {
      console.error('Error fetching certificates:', error);
    }
  };
  

  return (
    <div className='certi-container'>
      {loading ? (
        <img className='uhu' src={Uhu} alt="Loading..." />
      ) : (
        <>
          <CertificateFilter
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            certificates={certificates}
            childDetailsMap={childDetailsMap}
            handleCertificateClick={handleCertificateClick}
            onDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
          />
          {searchTerm.length === 0 && pagination && startDate === null && (
            <div className="pagination">
              {Array.from({ length: pagination.total_pages }, (_, index) => (
                <button
                  className={`page-select-button ${selectedPage === index + 1 ? 'selected-page' : ''}`}
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )}
          <div className='sorting-container'>
            <button className='sort-button' onClick={handleSortButtonClick}>
              Rendezés
            </button>
            {isSorting && (
              <div className="sort-options">
                <select name="field" value={sortingOption} onChange={handleSortingOptionChange}>
                  {sortingOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <button onClick={handleSortOrderChange}>
                  {sortOrder === 'asc' ? '▲' : '▼'}
                </button>
              </div>
            )}
          </div>
          {selectedCertificate && (
            <CertificateDetailsPage certificate={selectedCertificate} childDetails={childDetailsMap[selectedCertificate.child_id] || {}} />
          )}
        </>
      )}
      {error && showErrorModal && <AlertModal message={error} onClose={() => setShowErrorModal(false)} reload={false} />}
    </div>
  );
};

export default Previous;
