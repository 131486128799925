import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCalendar } from '@fortawesome/free-solid-svg-icons';

const FilterControls = ({ filter, handleFilterChange, handleDateChange }) => {

  return (
    <div className="filter-container">
      <div style={{ position: 'relative' }}>
        <input
          type="text"
          placeholder="Név szerinti szűrés"
          name="nameAndTajFilter"
          value={filter.nameAndTajFilter}
          onChange={handleFilterChange}
          className="input-field"
          style={{ paddingLeft: '30px' }}
        />
        <FontAwesomeIcon icon={faSearch} style={{ position: 'absolute', left: '16px', top: '50%', transform: 'translateY(-50%)', color: 'gray' }} />
      </div>

      <div style={{ position: 'relative' }}>
        <DatePicker
          selected={filter.birthdate ? new Date(filter.birthdate) : null}
          onChange={handleDateChange}
          placeholderText="Születési dátum szűrés"
          className="datepicker-field"
          isClearable
          showYearDropdown
          yearDropdownItemNumber={100}
          calendarStartDay={1}
          locale="hu"
          dateFormat="yyyy/MM/dd"
        />
        <FontAwesomeIcon icon={faCalendar} style={{ position: 'absolute', left: '16px', top: '50%', transform: 'translateY(-50%)', color: 'gray' }} />
      </div>
    </div>

  );
}

export default FilterControls;
