import React, { useState } from 'react';
import '../styles/TemplateModal.css';

const Modal = ({ message, errors, onClose, reload }) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleClose = () => {
        setIsOpen(false);
        if (reload) {
            window.location.reload();
        }
        onClose();
    };

    return (
        <>
            {isOpen && (
                <div className="template-modal">
                    <div className="template-modal-content">
                        <div>{message}</div>
                        {errors && errors.length > 0 && (
                            <div className="errors-list">
                                {errors.map((error, index) => (
                                    <p key={index}>
                                        {error.row}. sor - {error.error}
                                    </p>
                                ))}
                            </div>
                        )}
                        <button className='child-save' onClick={handleClose}>OK</button>
                    </div>
                </div>
            )}

        </>
    );
};

export default Modal;
