import React, { useState } from 'react';
import { forgotPinRequest } from '../api/ForgotPin';

const PinReminderModal = ({ doctorId, onClose }) => {
    const [message, setMessage] = useState('');

    const handleSendPinReminder = async () => {
        try {
            await forgotPinRequest(doctorId);
            setMessage("Email sikeresen elküldve");
        } catch (error) {
            setMessage("Hiba történt az email elküldése közben");
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <h2 className='title2'>Elfelejtett Pin kód</h2>
                {message && <p>{message}</p>}
                {!message && <p>Szeretnéd, hogy elküldjünk neked egy Pin kód viszzaállító linket emailben?</p>}
                {!message ? (
                    <div>
                        <button className='child-save' onClick={() => { handleSendPinReminder() }}>Igen</button>
                        <button className='close-button' onClick={onClose}>Mégsem</button>
                    </div>
                ) : (
                    <button className='child-save' onClick={onClose}>Rendben</button>
                )
                }
            </div>
        </div>
    );
};



export default PinReminderModal;
