import React, { useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/CertiDetails.css';
import Pdf from '../components/pdf';
import { fetchDoctorData } from '../api/DoctorId';
import handleExportClick from '../components/PdfExport';
import Uhu from '../assets/Uhu.png'
import AlertModal from '../components/AlertModal.js';

const CertificateDetailsPage = () => {
  const location = useLocation();
  const { certificate, childDetails } = location.state;
  const [doctorData, setDoctorData] = useState(null)
  const [error, setError] = useState(null);
  const pdfContentRef = useRef(null);
  const [isOneDaySickness, setIsOneDaySickness] = useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    const doctorId = parseInt(sessionStorage.getItem('doctorId'), 10);
    if(certificate.start_date === certificate.end_date){
      setIsOneDaySickness(true)
    }
    const fetchData = async () => {
      try {
        const data = await fetchDoctorData(doctorId);
        setDoctorData(data.doctor);
      } catch (err) {
        setShowAlertModal(true);
        setError("Hiba történt a szerverrel való kommunikáció közben!");
      }
    };
  
    fetchData();
  }, [certificate.end_date, certificate.start_date, isOneDaySickness]);  

  const handleExportClickWrapper = () => {
    handleExportClick(pdfContentRef, true);
  }; 

  return (
    <div className='certi-details-containter'>
      <h1 className="title3">Igazolás Részletei</h1>
      <div className='certi-details'>
        {certificate && childDetails && doctorData? (
          <div>
          <Pdf
              name={childDetails.name}
              address={childDetails.address}
              birthPlace={childDetails.birthplace}
              birth={childDetails.birthdate}
              mothersName={childDetails.mothers_name}
              //taj={childDetails.taj}
              startDate={certificate.start_date}
              endDate={certificate.end_date}
              reason={certificate.reason}
              communityDate={certificate.can_rejoin_community}
              excused={certificate.exempt_from_pe}
              PhysicalExcused={certificate.exemption_end_date}
              doctorData={doctorData}
              qr={certificate.uuid}
              con={pdfContentRef}
              isOneDaySickness={isOneDaySickness}
            />
          </div>
        ) : (
          <img className='uhu' src={Uhu} alt="Loading..." />
        )}
      </div>
      <div className='action-panel' >
      <button className='action-button' onClick={handleExportClickWrapper}>Igazolás pdf letöltése</button>
      </div>
      {error && showAlertModal && <AlertModal message={error} onClose={() => setShowAlertModal(false)} reload={false} />}
    </div>
  );
};

export default CertificateDetailsPage;
