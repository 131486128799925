import React, { useState } from 'react';
import RegistrationPage2 from './NotRequiredPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Identification from '../../assets/identification.pdf'

const RegistrationPage1 = ({ formData, fileName, handleChange, handleSubmit, errors }) => {
  const [optionalDataVisible, setOptionalDataVisible] = useState(false);

  const toggleOptionalData = () => {
    setOptionalDataVisible(!optionalDataVisible);
  };

  const fieldInfo = {
    name: "Teljes név, beleértve az előtagot is. Az itt megadott név fog megjelenni az orvosi igazoláson a kiállító orvos nevénél.",
    loginEmail: "Kérjük, hogy valós e-mail címet adj meg, mert az itt megadott címen keresztül kommunikálunk veled. Erre az e-mail címre küldjük majd az értesítést is, ha jóváhagytuk a regisztrációt.",
    registrationNumber: "5 számjegyből álló orvosi nyilvántartási szám (pecsétszám)",
    password: "Kérjük, hogy biztonságos jelszót adj meg. Javasoljuk, hogy jelszavad tartalmazzon kisbetűket, nagybetűket, számokat és különleges karaktereket is. A jelszónak minimum 8 karakternek kell lennie.",
    confirmPassword: "Az előzőleg megadott jelszavad újra.",
    pin: "A PIN kód egy személyes azonosító kód (jelszó), amit minden igazolás kiállításakor meg kell adnod. A kód megadásával hagyod jóvá az igazolás kiállítását. Rossz PIN kód megadása esetén nem állítjuk ki az igazolást. A PIN-nek 6 karakternek kell lennie",
    confirmPin: "Az előzőleg megadott PIN kód újra.",
    contactPhone: "Egy telefonszám, amellyel a szülő elérhet téged.",
    specialization: "Amennyiben a házi gyermekorvosi címen kívül további specializációkkal is rendelkezel, amit szeretnél, hogy megjelenjen az igazoláson is, itt adhatod meg (pl. neonatológus, gyermekkardiológus szakorvos stb.)",
    fileAttachments: "Fontos, hogy meg tudjunk győződni arról, hogy valóban orvosként regisztrálsz rendszerünkbe, ezzel elkerülve, hogy bárki hamis igazolásokat tudjon kiállítani, ezért kérünk, hogy töltsd fel a _________ dokumentumod szkennelt vagy fotózott példányát. Ezt a dokumentumot csak az ellenőrzési folyamat végéig tároljuk, regisztrációd jóváhagyása után töröljük.",
  };

  return (
    <>
      <h2 className='title2'>Regisztráció</h2>
      <label htmlFor="self-data">Orvos adatai</label>
      <div className='reg-validation-container'>
        <input
          type="text"
          id="name"
          className={`reg-input ${errors.name && 'reg-input-error'}`}
          placeholder='Név'
          name="name"
          value={formData.name}
          onChange={handleChange}
          title={fieldInfo.name}
          required
        />
        {errors.name && <p className="error-message">{errors.name}</p>}
      </div>

      <div className='reg-validation-container'>
        <input
          type="email"
          id="loginEmail"
          className={`reg-input ${errors.name && 'reg-input-error'}`}
          placeholder='Bejelentkezési Email'
          name="loginEmail"
          value={formData.loginEmail}
          onChange={handleChange}
          title={fieldInfo.loginEmail}
          required
        />
        {errors.loginEmail && <p className="error-message">{errors.loginEmail}</p>}
      </div>

      <div className='reg-validation-container'>
        <input
          type="text"
          id="registrationNumber"
          name="registrationNumber"
          className={`reg-input ${errors.name && 'reg-input-error'}`}
          placeholder='Orvos nyilvántartási szám (pecsétszám)'
          value={formData.registrationNumber}
          onChange={handleChange}
          title={fieldInfo.registrationNumber}
          required
        />
        {errors.registrationNumber && <p className="error-message">{errors.registrationNumber}</p>}
      </div>


      <div className='reg-validation-container'>
        <input
          type="password"
          id="password"
          name="password"
          className={`reg-input ${errors.name && 'reg-input-error'}`}
          placeholder='Jelszó'
          value={formData.password}
          onChange={handleChange}
          title={fieldInfo.password}
          required
        />
        {errors.password && <p className="error-message">{errors.password}</p>}
      </div>

      <div className='reg-validation-container'>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          className={`reg-input ${errors.name && 'reg-input-error'}`}
          placeholder='Jelszó újra'
          value={formData.confirmPassword}
          onChange={handleChange}
          title={fieldInfo.confirmPassword}
          required
        />
        {errors.confirmPassword && <p className="error-message">{errors.confirmPassword}</p>}
      </div>

      <div className='reg-validation-container'>
        <input
          type="password"
          id="pin"
          name="pin"
          className={`reg-input ${errors.name && 'reg-input-error'}`}
          placeholder='PIN kód'
          value={formData.pin}
          onChange={handleChange}
          title={fieldInfo.pin}
          required
        />
        {errors.pin && <p className="error-message">{errors.pin}</p>}
      </div>

      <div className='reg-validation-container'>
        <input
          type="password"
          id="confirmPin"
          name="confirmPin"
          className={`reg-input ${errors.name && 'reg-input-error'}`}
          placeholder='PIN kód újra'
          value={formData.confirmPin}
          onChange={handleChange}
          title={fieldInfo.confirmPin}
          required
        />
        {errors.confirmPin && <p className="error-message">{errors.confirmPin}</p>}
      </div>

      <div className='reg-validation-container'>
        <input
          type="text"
          id="contactPhone"
          name="contactPhone"
          className={`reg-input ${errors.name && 'reg-input-error'}`}
          placeholder='Kapcsolattartási telefon'
          value={formData.contactPhone}
          onChange={handleChange}
          title={fieldInfo.contactPhone}
          required
        />
        {errors.contactPhone && <p className="error-message">{errors.contactPhone}</p>}
      </div>

      <div className='spec-container'>
        <p>Specializációk</p>
        <div className='specialization'>
          <p>Alapbeállítás: Házi gyermekorvos</p>
          <p>Adjon meg további specializációt:</p>
        </div>
      </div>

      <input
        type="text"
        id="specialization"
        name="specialization"
        className='reg-input'
        placeholder='Specializáció'
        value={formData.specialization}
        onChange={handleChange}
        title={fieldInfo.specialization}
      />
      <div className='identification-container'>
        <label htmlFor="fileAttachments">Orvos azonosító lap:</label>
        <p className='doctor-file-p'>Töltsd le az orvos azonosító lapot és írd alá a dokumentumban leírtaknak megfelelően. Erre azért van szükség, hogy ellenőrizni tudjuk, hogy valóban orvosként kezdeményezel regisztrációt a rendszerünkbe. Ezzel tudjuk biztosítani, hogy ne kerüljön hamis igazolás kiállításra a Dooki-ból.</p>
        <div className='identification-button'>
          <a className='identification' href={Identification} download="orvosi_azonosito_lap.pdf">
            <FontAwesomeIcon className='download-icon' icon={faArrowDown} />
            Az orvos azonosító lap letöltése
          </a>
        </div>

        <div className='reg-file-upload-container'>
          <label className='reg-file' htmlFor="fileAttachments">Aláírt azonosító lap visszatöltése</label>
          <input
            type="file"
            id="fileAttachments"
            name="fileAttachments"
            onChange={handleChange}
            multiple
            title={fieldInfo.fileAttachments}
            style={{ opacity: 0, width: 0 }}
          />
          {!fileName ? (
            <input className='reg-file-name' type="text" value='Nincs fájl kiválasztva' readOnly disabled />
          ) : (
            <input className='reg-file-name' type="text" value={fileName} readOnly disabled />
          )}

        </div>
      </div>
      <button
        className='optional-button'
        type="button"
        onClick={() => toggleOptionalData()}
      >
        {optionalDataVisible ? 'Opcionális adatok ▼' : 'Opcionális adatok ▶'}
      </button>

      {optionalDataVisible && (
        <div className='optional-data'>
          <RegistrationPage2 formData={formData} handleChange={handleChange} />
        </div>
      )}

      <div className='checkbox-group'>
        <div>
          <input
            type="checkbox"
            id="acceptTerms"
            name="acceptTerms"
            checked={formData.acceptTerms}
            onChange={handleChange}
            required
          />
          <label htmlFor="acceptTerms">
            Az <a href="https://www.digitalisigazolas.hu/aszf/" target="_blank" rel="noopener noreferrer">Általános Szerződési Feltételeket</a> elolvastam, megértettem és elfogadom.
          </label>
          {errors.acceptTerms && <p className="error-message">{errors.acceptTerms}</p>}
        </div>

        <div>
          <input
            type="checkbox"
            id="acceptPrivacy"
            name="acceptPrivacy"
            checked={formData.acceptPrivacy}
            onChange={handleChange}
            required
          />
          <label htmlFor="acceptPrivacy">Az <a href="https://www.digitalisigazolas.hu/adatvedelem/" target="_blank" rel="noopener noreferrer">Adatkezelési Tájékoztatót</a> elolvastam, megértettem és elfogadom, hozzájárulok a regisztráció és a rendszer használata során megadott adatok kezeléséhez és tárolásához az Adatkezelési Tájékoztatóban leírtak szerint. Az általam megadott más személy (pl. beteg) adatait jogosult vagyok kezelni, azt a szolgáltatás igénybevétele céljából adom át adatfeldolgozásra. Tudomásul veszem, hogy jelen hozzájárulásaimat bármikor visszavonhatom.</label>
          {errors.acceptPrivacy && <p className="error-message">{errors.acceptPrivacy}</p>}
        </div>
        <div>
          <input
            type="checkbox"
            id="subscribeNewsletter"
            name="subscribeNewsletter"
            checked={formData.subscribeNewsletter}
            onChange={handleChange}
          />
          <label htmlFor="subscribeNewsletter">Szeretnék feliratkozni a Dooki hírlevelére.</label>
        </div>
      </div>

      <button className='page-button' type="button" onClick={handleSubmit}>
        Regisztráció
      </button>
    </>

  );
}

export default RegistrationPage1;
