import React, { useState } from 'react';
import Modal from 'react-modal';
import { exportDoctorData } from '../api/DataExport';
import { deleteDoctor } from '../api/DeleteDoctor';
import { logout } from '../api/LogoutApi';
import AlertModal from '../components/AlertModal.js';

const DeleteUserModal = ({ isOpen, onClose }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmationText, setConfirmationText] = useState('');
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [confirmationError, setConfirmationError] = useState('');
    const [error, setError] = useState(null);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const doctorId = sessionStorage.getItem('doctorId');
  
    const handleExportData = async () => {
        try {
            setExportLoading(true);
            const blob = await exportDoctorData(doctorId);
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'doctor_data.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            setShowAlertModal(true);
            setError("Hiba történt a szerverrel való kommunikáció közben!");
        } finally {
            setExportLoading(false);
        }
    };

    const handleDelete = async () => {
        if (password && confirmPassword) {
            if (password === confirmPassword) { 
                if (confirmationText.trim().toLowerCase() === 'törlés') {
                    try {
                        setLoading(true);
                        const deleteResult = await deleteDoctor(doctorId, password);
    
                        if (deleteResult.error) {
                            if (deleteResult.error === 'Password is incorrect') {
                                setPasswordError('A megadott jelszó helytelen.');
                                setConfirmationError('');
                            } else {
                                setPasswordError('Hiba történt a felhasználó törlése során.');
                            }
                        } else {
                            const logoutResult = await logout();
    
                            if (logoutResult.success) {
                                sessionStorage.removeItem('doctorId');
                                sessionStorage.removeItem('accepted');
                                sessionStorage.removeItem('isAuthenticated');
                                onClose();
                                window.location.href = '/login';
                            } else {
                                console.error('Logout error:', logoutResult.error);
                            }
                        }
                    } catch (error) {
                        setShowAlertModal(true);
                        setError("Hiba történt a szerverrel való kommunikáció közben!");
                    } finally {
                        setLoading(false);
                    }
                } else {
                    setConfirmationError('A törlés megerősítéséhez írd be a "törlés" szót.');
                    setPasswordError('');
                }
            } else {
                setPasswordError('A két jelszó nem egyezik meg.');
                setConfirmationError('');
            }
        } else {
            setPasswordError('A mezőket kötelező kitölteni.');
            setConfirmationError('');
        }
    };
    
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Felhasználó törlése"
            className='delete-user-modal'
        >
            <h2 className='title2'>Felhasználó törlése</h2>
            <p>Az alábbi ablakban tudod törölni felhasználói fiókodat!</p>
            <p>A következő adatok fognak törlődni:</p>
            <ul className='delete-li'>
                <li>Feltöltött gyermekadatok</li>
                <li>Korábbi igazolások</li>
                <li>Korábbi jegyzetek</li>
                <li>Megadott egyéb adatok</li>
            </ul>
            <p>A fiók törlésével a fenti adatok automatikusan  és visszavonhatatlanul törlődni fognak, az adatok utólag nem visszaállíthatók!</p>
            <p>Ezeket az adatokat a törlés előtt egy Excel fájlba exportálhatod a rendszerből, az oldal alján található gomb segítségével.</p>
            <div className='field-picker'>
                <input
                    type="password"
                    placeholder="Jelszó"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={passwordError ? "error-picker" : "date-picker"}
                />
                <div className="error-message">{passwordError}</div>
            </div>
            <div className='field-picker'>
                <input
                    type="password"
                    placeholder="Jelszó újra"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className={passwordError ? "error-picker" : "date-picker"}
                />
            </div>
            <div className='field-picker'>
                <input
                    type="text"
                    placeholder="A megerősítéshez írd be, hogy 'törlés'"
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                    className={confirmationError ? "error-picker" : "date-picker"}
                />
                <div className="error-message">{confirmationError}</div>
            </div>
            <div>
                <button className='reg-button' onClick={handleDelete} disabled={loading}>{loading ? 'Törlés...' : 'Adatok törlése'}</button>
                <button className='close-button' onClick={onClose}>Bezárás</button>
            </div>
            <button className='data-export-button' onClick={handleExportData} disabled={exportLoading}>{exportLoading ? 'Exportálás...' : 'Felhasználói adatok exportálása'}</button>
            {error && showAlertModal && <AlertModal message={error} onClose={() => setShowAlertModal(false)} reload={false} />}
        </Modal>
    );
};

export default DeleteUserModal;
