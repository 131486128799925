import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { resetPin } from '../api/ResetPin';
import '../styles/ResetPassword.css';

const ResetPin = () => {
    const { token } = useParams();
    const [newPin, setNewPin] = useState('');
    const [confirmPin, setConfirmPin] = useState('');
    const [resetResult, setResetResult] = useState({ success: null, error: null });
    const [pinMatch, setPinMatch] = useState(true);
    const [message, setMessage] = useState('');

    const handleResetPin = async () => {
        try {
            if (newPin === confirmPin) {
                const result = await resetPin(token, newPin);
                setResetResult(result);
                setMessage('Sikeres Pin kód változtatás');
            } else {
                setPinMatch(false);
            }
        } catch (error) {
            setResetResult({ success: false, error: 'Pin kód változtatás sikertelen.' });
        }
    };

    return (
        <div className='reset-container'>
            <div className='reset-form'>
                <h1 className="title2">Pin visszaállítása</h1>
    
                {resetResult.success === null && (
                    <>
                        <div className="field-picker">
                            <input
                                type="password"
                                value={newPin}
                                placeholder='Adja meg az új Pin kódot'
                                className='date-picker'
                                onChange={(e) => setNewPin(e.target.value)}
                            />
                        </div>
    
                        <div className="field-picker">
                            <input
                                type="password"
                                value={confirmPin}
                                placeholder='Adja meg az új Pin kódot mégegyszer'
                                className='date-picker'
                                onChange={(e) => setConfirmPin(e.target.value)}
                            />
                        </div>
                    </>
                )}
    
                {!pinMatch && (
                    <p className='password-error'>A két Pin kódanak egyeznie kell!</p>
                )}
    
                {resetResult.success === null && (
                    <button className='reg-button' onClick={handleResetPin}>Pin kód visszaállítása</button>
                )}
    
                {resetResult.success !== null && (
                    <div>
                        {resetResult.success ? (
                            <div className='success-container'>
                                <p style={{ color: 'green' }}>{message}</p>
                            </div>
                        ) : (
                            <p style={{ color: 'red' }}>{resetResult.error}</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );    
};

export default ResetPin;
