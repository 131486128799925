import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { fetchCertificateData } from '../api/CertiId';
import { getChildDetails } from '../api/ChildId';
import { fetchDoctorData } from '../api/DoctorId';
import Pdf from '../components/pdf';
import Uhu from '../assets/Uhu.png';
import AlertModal from '../components/AlertModal.js';

const CertificateDetails = ({ isOpen, onRequestClose, certificateId }) => {
  const [certificateData, setCertificateData] = useState(null);
  const [error, setError] = useState(null);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [childData, setChildData] = useState(null);
  const [isOneDaySickness, setIsOneDaySickness] = useState(false);
  const [doctorData, setDoctorData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const certificateData = await fetchCertificateData(certificateId);
        setCertificateData(certificateData);
        console.log(certificateData);

        const childId = certificateData.child_id;
        const childDetails = await getChildDetails(childId);
        setChildData(childDetails);
        console.log(childDetails);

        if (certificateData.start_date === certificateData.end_date) {
          setIsOneDaySickness(true);
        }

        const doctorId = parseInt(sessionStorage.getItem('doctorId'), 10);
        const doctorData = await fetchDoctorData(doctorId);
        console.log(doctorData);
        setDoctorData(doctorData);
      } catch (error) {
        setShowAlertModal(true);
        setError("Hiba történt a szerverrel való kommunikáció közben!");
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen, certificateId]);

  if (!isOpen) {
    return null;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!certificateData) {
    return <img className='uhu' src={Uhu} alt="Loading..." />;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Certificate Details Modal"
      className='note-certi-modal'
    >
      <div className='details-container'>
        {certificateData && childData && doctorData ? (
          <Pdf
            name={childData.name}
            address={childData.address}
            birthPlace={childData.birthplace}
            birth={childData.birthdate}
            mothersName={childData.mothers_name}
            startDate={certificateData.start_date}
            endDate={certificateData.end_date}
            reason={certificateData.reason}
            communityDate={certificateData.can_rejoin_community}
            excused={certificateData.exempt_from_pe}
            PhysicalExcused={certificateData.exemption_end_date}
            qr={certificateData.uuid}
            isOneDaySickness={isOneDaySickness}
          />
        ) : (
          <img className='uhu' src={Uhu} alt="Loading..." />
        )}
      </div>
      {error && showAlertModal && <AlertModal message={error} onClose={() => setShowAlertModal(false)} reload={false} />}
    </Modal>
  );
};

export default CertificateDetails;
