import React, { useState, useEffect } from 'react';
import { fetchDoctorData } from '../api/DoctorId';
import { updateDoctorData } from '../api/Settings';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import SettingsFields from '../components/SettingsFields';
import Scrolling from '../components/Scrolling.js';
import FileUploadModal from '../components/DataImportModal.js';
import '../styles/Settings.css';
import Uhu from '../assets/Uhu.png';
import AlertModal from '../components/AlertModal.js';
import ImportAlert from '../components/ImportAlert.js';
import ChildDataImport from '../api/ChildDataImport.js';
import DeleteUserModal from '../components/DeleteUserModal';

const Settings = () => {
  const [doctorData, setDoctorData] = useState(null);
  const [error, setError] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [isOfficeHoursOpen, setIsOfficeHoursOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [isFileUploadModalVisible, setIsFileUploadModalVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [fileCount, setFileCount] = useState(null);
  const [message, setMessage] = useState(null);
  const [showAlertModal2, setShowAlertModal2] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [templateErrors, setTemplateErrors] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const isScrollingDown = Scrolling();

  useEffect(() => {
    const doctorId = parseInt(sessionStorage.getItem('doctorId'), 10);

    const fetchData = async () => {
      try {
        const data = await fetchDoctorData(doctorId);
        setDoctorData(data.doctor);
        setFileCount(data.doctor.file_count);
        setIsLoading(false);
      } catch (err) {
        setError("Hiba történt a szerverrel való kommunikáció közben!");
        setShowErrorModal(true);
        setIsLoading(false);
      }
    };    

    fetchData();
  }, []);

  const handleInputChange = (field, value) => {
    if (field === 'specialization' && value.trim() === '') {
      setDoctorData(prevData => ({
        ...prevData,
        [field]: value
      }));
    } else {
      setDoctorData(prevData => ({
        ...prevData,
        [field]: value
      }));
  
      const errorsObj = {};
      if (!value) {
        errorsObj[field] = `A mező kitöltése kötelező`;
      } else {
        errorsObj[field] = '';
      }
      setErrors(prevErrors => ({
        ...prevErrors,
        ...errorsObj
      }));
    }
  };
  

  const handleClinicAddressChange = (field, value) => {
    setDoctorData((prevData) => ({
      ...prevData,
      clinic_address: {
        ...prevData.clinic_address,
        [field]: value,
      },
    }));
  };

  const handleOfficeHoursChange = (day, value) => {
    setDoctorData((prevData) => ({
      ...prevData,
      office_hours: {
        ...prevData.office_hours,
        [day]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      const isValid = Object.values(errors).every(error => !error);
      if (!isValid) {
        setShowValidationModal(true);
        return;
      }
  
      await updateDoctorData(doctorData, newFile);
      setMessage("Adatok sikeresen mentve!");
      setShowAlertModal(true);
    } catch (error) {
      if (error.status === 413) {
        setMessage("A fájl mérete túl nagy! (Maximum 5MB).");
        setShowAlertModal(true);
      }
    }
  };
  
  const isFileUploadDisabled = doctorData && (fileCount >= 2 || sessionStorage.getItem('accepted') === 'true');

  const handleCloseFileUploadModal = () => {
    setIsFileUploadModalVisible(false);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const fileSizeInMegabytes = selectedFile.size / (1024 * 1024);
  
    if (fileSizeInMegabytes > 5) {
      setMessage("A fájl mérete túl nagy! Maximum 5 MB lehet.");
      setShowAlertModal(true);
      return;
    }
  
    setFile(selectedFile);
  };
  

  const handleAdatimportClick = () => {
    setIsFileUploadModalVisible(true);
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();

    try {
        const response = await ChildDataImport.addChildren(file);

        if (response.success) {
            if (response.errors) {
                setMessage(response.success);
                setTemplateErrors(response.errors);
            } else {
                setMessage("Az adatok beolvasása sikeres.");
            }

            setShowAlertModal2(true);
            setIsFileUploadModalVisible(false);
        }
    } catch (error) {
        setShowAlertModal2(true);
        setIsFileUploadModalVisible(false);
        setMessage("Az adatok beolvasása sikertelen volt.");
    }
  };

  return (
    <div className={`${isScrollingDown ? 'scroll' : ''}`}>
      <h1 className='title3'>Beállítások</h1>
      <div className="settings-container">
        {isLoading && <img className='uhu' src={Uhu} alt="Loading..." />}
        {!isLoading && doctorData && (
          <div className='settings-form'>
            <SettingsFields
              doctorData={doctorData}
              handleInputChange={handleInputChange}
              handleClinicAddressChange={handleClinicAddressChange}
              handleOfficeHoursChange={handleOfficeHoursChange}
              setIsOfficeHoursOpen={setIsOfficeHoursOpen}
              isOfficeHoursOpen={isOfficeHoursOpen}
              setNewFile={setNewFile}
              isFileUploadDisabled={isFileUploadDisabled}
              errors={errors}
            />

            <button className="submit-button" onClick={handleSubmit}>
              Mentés
            </button>

            <button className='delete-user' onClick={() => setIsDeleteUserModalOpen(true)}>Felhasználó törlése</button>

            <DeleteUserModal
              isOpen={isDeleteUserModalOpen}
              onClose={() => setIsDeleteUserModalOpen(false)}
            />

          </div>
        )}
      </div>

      {sessionStorage.getItem('accepted') === 'null' &&
        <div className='action-panel'>
          <button className='add-child' onClick={handleAdatimportClick}>
            Gyermekek adatainak importálása
          </button>
          <FileUploadModal
            isOpen={isFileUploadModalVisible}
            handleCloseFileUploadModal={handleCloseFileUploadModal}
            handleFileUpload={handleFileUpload}
            handleFileChange={handleFileChange}
          />
        </div>
      }
      {error && showErrorModal && <AlertModal message={error} onClose={() => setShowErrorModal(false)} reload={false} />}
      {(sessionStorage.getItem('accepted') === 'null' || sessionStorage.getItem('accepted') === 'false') && <AlertModal message={"Amíg a fiókod nincs jóváhagyva, addig csak a beállításokat éred el!"} onClose={() => setShowAlertModal(false)} reload={false} />}
      {showAlertModal && <AlertModal message={message} onClose={() => setShowAlertModal(false)} reload={true} />}
      {showValidationModal && <AlertModal message={"Kérjük töltsd ki az összes mezőt"} onClose={() => setShowValidationModal(false)} reload={false} />}
      {showAlertModal2 && <ImportAlert message={message} errors={templateErrors} onClose={() => setShowAlertModal(false)} reload={true} />}
    </div>
  );
};

export default Settings;
