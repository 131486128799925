import API_BASE_URL from './ApiConfig';

const ApiService = {
  addChildren: async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${API_BASE_URL}/api/children`, {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });

      const responseData = await response.json();

      if (!response.ok) {
        if (responseData.errors) {
          throw new Error(responseData.errors[0].error || 'Error from server');
        } else {
          throw new Error(responseData[0].error || 'Error from server');
        }
      }

      return { success: responseData.success, errors: responseData.errors, error: responseData.error };
    } catch (error) {
      throw new Error('Error connecting to the server');
    }
  },
};

export default ApiService;
