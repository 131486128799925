import API_BASE_URL from './ApiConfig';

const resetPin = async (pinResetToken, newPin) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/reset_pin/${pinResetToken}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ new_pin: newPin }).toString(),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error occurred while resetting PIN:', error);
        throw new Error('Váratlan hiba lépett fel PIN-kód visszaállítása közben');
    }
};

export { resetPin };
