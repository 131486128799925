import API_BASE_URL from './ApiConfig';

const registerUser = async (formData) => {
  try {
    const formDataToSend = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'files') {
        value.forEach(file => formDataToSend.append('files', file));
      } else {
        formDataToSend.append(key, value);
      }
    });

    const response = await fetch(`${API_BASE_URL}/api/register`, {
      method: 'POST',
      body: formDataToSend,
      credentials: 'include'
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message || 'An unexpected error occurred');
  }
};

export { registerUser };
