import React, { useState } from 'react';
import UpdateChild from '../api/UpdateChild.js'
import AlertModal from '../components/AlertModal.js';

function CertiSubmitModal({ pin, setPin, pinError, successMessage, handlePinSubmit, handleExportAndAddCertificate, closeModal, emailEmpty, setEmailEmpty, childId }) {
  const doctorId = parseInt(sessionStorage.getItem('doctorId'), 10);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showAlertModal2, setShowAlertModal2] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    parent_email: '',
  });

  const handleSaveClick = async () => {
    try {
      const dataToBeSent = {
        ...formData,
        doctor_id: doctorId,
      };
      const response = await UpdateChild.updateChild(childId, dataToBeSent)

      if (response.success) {
        setShowAlertModal(true);
        setEmailEmpty(false);
      } else {
        console.error('Mentés sikertelen', response.error);
      }
    } catch (error) {
      setError("Hiba történt a szerverrel való kommunikáció közben!");
      setShowAlertModal2(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div>
      {successMessage ? (
        <div>
          <p style={{ color: 'green' }}>{successMessage}</p>
          <button className='certi-button' onClick={closeModal}>
            Rendben
          </button>
        </div>
      ) : (
        <>
          {emailEmpty && (
            <p className='warning'>Figyelem: A szülőnek nincs megadva e-mail címe, ezért az igazolás csak az adatbázisban kerül rögzítésre, illetve letöltve adhatot oda a gyermeknek!</p>
          )}
          {emailEmpty &&
            <div>
              <input
                type="text"
                id="parent_email"
                className='email-input'
                placeholder='Szülő e-mail címe'
                name="parent_email"
                value={formData.parent_email}
                onChange={handleChange}
                required
              />
              <button className='add-child' onClick={handleSaveClick}>Email hozzáadása</button>
            </div>
          }
          <p className="pin-message">Add meg a PIN kódodat!</p>
          <input
            type="password"
            placeholder="PIN kód"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            className="custom-input"
          />
          <div className="certi-buttons">
            <div>
              {pinError && <p className='pin-error'>{pinError}</p>}
              <button className='certi-confirm-button' onClick={handlePinSubmit}>
                {emailEmpty ? "Mentés az adatbázisba" : "Küldés e-mailben a szülőnek"}
              </button>
              <button
                className="certi-confirm-button"
                onClick={handleExportAndAddCertificate}
              >
                {emailEmpty ? "Igazolás letöltése" : "Küldés e-mailben és letöltés"}
              </button>
            </div>
          </div>
        </>
      )}
      {showAlertModal && <AlertModal message={"Az email sikeresen mentve"} onClose={() => setShowAlertModal(false)} reload={false} />}
      {error && showAlertModal2 && <AlertModal message={error} onClose={() => setShowAlertModal(false)} reload={false} />}
    </div>
  );
}

export default CertiSubmitModal;
