import API_BASE_URL from './ApiConfig';

export const updateDoctorData = async (updatedDoctorData, newFile) => {
  try {
    const doctorId = parseInt(sessionStorage.getItem('doctorId'), 10);
    const apiUrl = `${API_BASE_URL}/api/settings/${doctorId}`;

    const { file_attachments, ...cleanedDoctorData } = updatedDoctorData;

    const formData = new FormData();

    if (newFile) {
      formData.append('newFile', newFile);
    }

    formData.append('doctorData', JSON.stringify(cleanedDoctorData));

    const response = await fetch(apiUrl, {
      method: 'PUT',
      body: formData,
      credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw { status: response.status, message: errorData.error };
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw error;
  }
};

