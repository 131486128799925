import API_BASE_URL from './ApiConfig';

const deleteDoctor = async (doctorId, password) => {
    try {
        const formData = new FormData();
        formData.append('password', password);
        const response = await fetch(`${API_BASE_URL}/api/delete_doctor/${doctorId}`, {
            method: 'DELETE',
            body: formData,
            credentials: 'include'
        });

        if (response.ok) {
            return { success: true };
        } else {
            const errorMessage = await response.text();
            return { error: errorMessage };
        }
    } catch (error) {
        return { error: error.message };
    }
};

export { deleteDoctor };
