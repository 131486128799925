import API_BASE_URL from './ApiConfig';

const fetchCertificateRequests = async () => {
    try {

        const response = await fetch(`${API_BASE_URL}/api/certificate_requests`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error('Failed to fetch certificate requests');
        }

        const data = await response.json();
        return (data);
    } catch (error) {
        throw error;
    }
};

export default fetchCertificateRequests;
