import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../api/LogoutApi'
import '../styles/ProfileMenu.css';

function ProfileMenu({ onClose, onLogout }) {
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleLogout = async () => {

    const result = await logout();

    if (result.success) {
      onLogout(false);
    } else {
      console.error('Logout failed:', result.error);
    }
  };

  return (
    <div className="profile-menu" ref={menuRef}>
      <Link to="/Beállítások" className="profile-item">Beállítások</Link>
      <Link to="/kapcsolat" className="profile-item">Kapcsolat</Link>
      <button className="profile-item" onClick={handleLogout}>Kijelentkezés</button>
    </div>
  );
}

export default ProfileMenu;
