import API_BASE_URL from './ApiConfig';

const loginApi = async (LoginEmail, password) => {
  try {
    const formData = new FormData();
    formData.append('LoginEmail', LoginEmail);
    formData.append('password', password);

    const response = await fetch(`${API_BASE_URL}/api/login`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    if (response.ok) {
      const data = await response.json();
      sessionStorage.setItem('doctorId', data.user_id);
      sessionStorage.setItem('accepted', data.accepted);
      sessionStorage.setItem('hasToken', data.token);
      localStorage.setItem('EnableVirtual', true);
      if (data.token === true) {
        sessionStorage.setItem('isAuthenticated', 'true');
      }
      return { success: true, hasToken: data.token, userid: data.userid, accepted: data.accepted };
    } else {
      const data = await response.json();
      return { success: false, error: data.error };
    }
  } catch (error) {
    console.log(error.message)
    return { success: false, error: 'Hiba a szerverrel való kommunikáció során' };
  }
};

export default loginApi;
