import API_BASE_URL from './ApiConfig';

export const forgotPinRequest = async (doctorId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/forgot_pin/${doctorId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('Váratlan hiba lépett fel PIN-kód visszaállítás kérése közben');
  }
};
