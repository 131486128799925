import API_BASE_URL from './ApiConfig';
import { fetchCsrfToken } from './CsrfToken';

export const addDoctorNote = async (Note, childId) => {
  const doctorId = sessionStorage.getItem('doctorId');
  const createdAt = new Date().toISOString();

  const formData = new FormData();
  formData.append('doctor_id', doctorId);
  formData.append('note', Note);
  formData.append('child_id', childId);
  formData.append('created_at', createdAt);

  try {
    const csrfToken = await fetchCsrfToken();
    const response = await fetch(`${API_BASE_URL}/api/add_note`, {
      method: 'POST',
      headers: {
        'X-CSRFToken': csrfToken,
      },
      body: formData,
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to add doctor note');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

