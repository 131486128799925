import React, { useState } from 'react';
import '../styles/AlertModal.css';

const Modal = ({ message, onClose, reload}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    if (reload){
        window.location.reload();
    }
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>{message}</p>
            <button className='child-save' onClick={handleClose}>OK</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
