import React from 'react';
import QRCode from 'qrcode.react';
import 'jspdf-autotable';
import '../styles/pdf.css';
import Logo1 from '../assets/logo1.png';

function Pdf({ name, address, birthPlace, birth, mothersName, /*taj,*/ startDate, endDate, reason, communityDate, excused, PhysicalExcused, doctorData, qr, con, isOneDaySickness }) {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('hu-HU', options);
  };
  
  const qrCode = `https://dooki.hu/ellenorzes/${qr}`;

  const formattedStartDate = startDate ? formatDate(startDate) : '';
  const formattedEndDate = endDate ? formatDate(endDate) : '';
  const formattedComDate = communityDate ? formatDate(communityDate) : '';
  const formattedBirth = birth ? formatDate(birth) : '';
  const formattedPhysicalExcused = PhysicalExcused ? formatDate(PhysicalExcused) : '';

  let message;
if (isOneDaySickness) {
  message = (
    <p className='cont'>
      Igazolom, hogy a fent nevezett gyermek{' '}
      <span className='bold-date'>{formattedStartDate}</span> napján nem látogathatja a nevelési-oktatási intézményt.
    </p>
  );
} else {
  message = (
    <p className='cont'>
      Igazolom, hogy a fent nevezett gyermek{' '}
      <span className='bold-date'>{formattedStartDate}</span> napjától{' '}
      <span className='bold-date'>{formattedEndDate}</span> napjáig nem látogathatja a nevelési-oktatási intézményt.
    </p>
  );
}

  return (
    <div ref={con}>
      <div className="field3">
        <div className='doc-data'>
          <p>{doctorData && doctorData.name} - {doctorData && doctorData.specialization ? 'Gyermek háziorvosi és ' + doctorData.specialization + ' Praxis'  : 'Gyermek háziorvosi Praxis,' }</p>
          <p>{doctorData && doctorData.clinic_address.zipCode + ' ' + doctorData.clinic_address.city + ' ' + doctorData.clinic_address.streetAndNumber}</p>
          <p>{doctorData && doctorData.clinic_phone}</p>
        </div>
        <h1 className="igazolas-title">Igazolás</h1>
        <h4 className="child">A gyermek</h4>
        <div className='child-data'>
          <p className="data">Neve: {name}</p>
          <p className="data">Lakcíme: {address}</p>
          <p className="data">Születési helye, ideje: {birthPlace}, {formattedBirth}</p>
          <p className="data">Anyja neve: {mothersName}</p>
        </div>
        <div className="line2"></div>
        <div className='igazolas-content'>
          {message}
          <p className="cont2">Távolmaradás oka: {reason}</p>
          <p className="cont">A gyermek közösségbe mehet <span className='bold-date'>{formattedComDate}</span> napjától.</p>
          <p className="cont">A gyermek testnevelés óra alól felmentve: {excused ? `${formattedPhysicalExcused} -ig` : 'Nem'}</p>
        </div>
        <p className='law'>Ez az igazolás megfelel a törvényben foglaltaknak, 20/2012. (VIII. 31.) EMMI rendelet 51. § (2d) bekezdése szerinti dokumentum. Aláírás nélkül is érvényes.</p>
        <p className="doctor">Igazolást kiállító orvos neve, pecsétszáma: {doctorData && doctorData.name}, {doctorData && doctorData.registration_number}</p>
        <div className='qr-code'>
          <p>Az igazolás egyedi azonosítója:</p>
          <QRCode value={qrCode} size={95} />
          <p>{qr}</p>
          <p className='check'>Az igazolás hitelessége itt ellenőrizhető:</p>
          <p>https://dooki.hu/ellenorzes</p>
        </div>
        <div className="line2"></div>
        <div className='added'>
          <p>Ez az igazolás a Dooki programjával készült.<br />Egyszerű igazolás orvosoknak és szülőknek egyaránt:<br />digitalisigazolas.hu</p>
          <img className='logo1' src={Logo1} alt="nothing" />
        </div>
        <div className="line2"></div>
      </div>
      </div>

  );
}

export default Pdf;