import API_BASE_URL from './ApiConfig';

const CertiEmail = async (file, doctorNote, childId, uuid) => {
  try {
    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('doctor_note', doctorNote);
    formData.append('child_id', childId);
    const response = await fetch(`${API_BASE_URL}/api/certificates/send/${uuid}`, {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    if (response.ok) {
      const result = await response.json();
      return { success: true, message: result.message };
    } else {
      const errorResult = await response.json();
      return { success: false, error: errorResult.error };
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export default CertiEmail;
