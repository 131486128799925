import API_BASE_URL from './ApiConfig';


export const rejectCertificateRequest = async (certificateRequestId, pinCode, reason) => {
    try {
        const formData = new FormData();
        formData.append('pin_code', pinCode);
        formData.append('reason', reason);

        const response = await fetch(`${API_BASE_URL}/api/certificate_requests/${certificateRequestId}/reject`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
        });

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.error || 'Failed to reject certificate request');
        }

        const data = await response.json();
        return { success: true, message: data.message };
    } catch (error) {
        throw new Error(error.message || 'An error occurred');
    }
};
