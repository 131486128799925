import API_BASE_URL from './ApiConfig';

const handleChildDeletion = async (childId) => {
  try {
    await fetch(`${API_BASE_URL}/api/children/${childId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });
  } catch (error) {
    throw error;
  }
};

export { handleChildDeletion };
