import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { registerUser } from '../api/RegApi';
import RegistrationPage1 from '../components/Registration/RequiredPage';
import '../styles/Registration.css';
import NameLogo from '../assets/NameLogo.png';

const RegistrationForm = () => {
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    loginEmail: '',
    registrationNumber: '',
    password: '',
    confirmPassword: '',
    pin: '',
    confirmPin: '',
    clinicPhone: '',
    contactEmail: '',
    contactPhone: '',
    acceptTerms: false,
    acceptPrivacy: false,
    subscribeNewsletter: false,
    zipCode: '',
    city: '',
    streetAndNumber: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    counseling: '',
    specialization: '',
    files: [],
  });

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
  
    if (type === 'file') {
      setFormData((prevData) => ({
        ...prevData,
        files: Array.from(files),
      }));
      
      setFileName(files[0] ? files[0].name : '');
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validationErrors = {};

    if (!formData.name) {
      validationErrors.name = "Kérjük, add meg a neved!";
    } else if (formData.name.length < 5) {
      validationErrors.name = "A névnek legalább 5 karakternek kell lennie!";
    }

    if (!formData.loginEmail) {
      validationErrors.loginEmail = "Kérjük, add meg az e-mail címed!";
    } else if (!/\S+@\S+\.\S+/.test(formData.loginEmail)) {
      validationErrors.loginEmail = "Kérjük, adj meg egy érvényes e-mail címet!";
    }

    if (!formData.registrationNumber) {
      validationErrors.registrationNumber = "Kérjük, add meg a nyilvántartási számodat!";
    } else if (formData.registrationNumber.length !== 5) {
      validationErrors.registrationNumber = "A nyilvántartási számnak 5 karakterből kell állnia!";
    }

    if (!formData.password) {
      validationErrors.password = "Kérjük, adj meg egy jelszót!";
    } else if (formData.password.length < 8) {
      validationErrors.password = "A jelszónak legalább 8 karakterből kell állnia!";
    }

    if (!formData.confirmPassword) {
      validationErrors.confirmPassword = "Kérjük, add meg a jelszót mégegyszer!";
    } else if (formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = "A jelszavak nem egyeznek meg!";
    }

    if (!formData.pin) {
      validationErrors.pin = "Kérjük, adj meg egy PIN kódot!";
    } else if (formData.pin.length !== 6) {
      validationErrors.pin = "A PIN kódnak 6 karakterből kell állnia!";
    }

    if (!formData.confirmPin) {
      validationErrors.confirmPin = "Kérjük, add meg a PIN kódot mégegyszer!";
    } else if (formData.pin !== formData.confirmPin) {
      validationErrors.confirmPin = "A PIN kódok nem egyeznek!";
    }

    if (!formData.contactPhone) {
      validationErrors.contactPhone = "Kérjük, adj meg egy telefonszámot!";
    }

    if (!formData.acceptTerms) {
      validationErrors.acceptTerms = "Kérjük, fogadd el az általános feltételeket!";
    }

    if (!formData.acceptPrivacy) {
      validationErrors.acceptPrivacy = "Kérjük, fogadd el az adatvédelmi szabályzatot!";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {; 
      const response = await registerUser(formData);
    
      if (response.success) {
        setResult(response);
        setMessage('Sikeres Regisztráció');
        setErrors({});
      }
    } catch (error) {
    
      let regErrors = {};
    
      if (error.message.includes("Ezzel az email címmel már regisztráltak")) {
        regErrors.loginEmail = "Ez az e-mail cím már regisztrálva van.";
      } else if (error.message.includes("Ezzel a nyilvántartási számmal már regisztráltak")) {
        regErrors.registrationNumber = "Ez a nyilvántartási szám már regisztrálva van.";
      } else {
        regErrors.general = "Nem sikerült regisztrálni. Kérjük, próbáld újra később.";
      }
    
      setErrors(regErrors);
    }    
  }

  return (
    <div>
      {result === null ? (
        <div className='registration-container'>
          <form className='reg-form' onSubmit={handleSubmit}>
            <div className='additional'>
              <img className='name-logo' src={NameLogo} alt="DookiLogo" />
              <p>Hozz létre orvosként ingyenes Dooki fiókot és próbáld ki szolgáltatásainkat.</p>
              <p>Regisztrációd jóváhagyását követően azonnal elkezdheted kiállítani az első digitális igazolást, kipróbálhatod #DIGITAL csomagunk extra funkcióit, amire később elő is fizethetsz.</p>
              <p>Előfizetés nélkül is tudsz e-igazolást kiállítani rendszerünkből.</p>
              <div>
                <p className="login-link">Már van fiókod? <Link to="/login">Jelentkezz be</Link></p>
              </div>
            </div>
            <RegistrationPage1 formData={formData} fileName={fileName} handleChange={handleChange} handleSubmit={handleSubmit} errors={errors} />
          </form>
        </div>
      ) : (
        <div className='result-container'>
          <div>
            {result.success ? (
              <div>
                <img className='name-logo' src={NameLogo} alt="DookiLogo" />
                <h2 className='title2' style={{ color: 'green' }}>{message}</h2>
                <p>Kérjük az email-ben kapott linken keresztül erősítsd meg az email címed!</p>
                <p>Ezen a linken tudsz bejelentkezni</p>
                <Link className='log-link' to="/login">Bejelentkezés</Link>
              </div>
            ) : (
              <p style={{ color: 'red' }}>Sikertelen regisztráció</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default RegistrationForm;
