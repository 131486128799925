import API_BASE_URL from './ApiConfig';

const fetchDoctorData = async (doctorId) => {
  try {
    const apiUrl = `${API_BASE_URL}/api/doctors/${doctorId}`;
    
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error.message || 'An unexpected error occurred';
  }
};

export { fetchDoctorData };
