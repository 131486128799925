import API_BASE_URL from './ApiConfig';

export const fetchCertificateData = async (certificateId) => {
 
    try {
      const response = await fetch(`${API_BASE_URL}/api/certificates/${certificateId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error.message || 'An error occurred while fetching the certificate.';
    }
  };
  
