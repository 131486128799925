import API_BASE_URL from './ApiConfig';

const getChildDetails = async (childId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/children/${childId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch child data');
      }
  
      return await response.json();
    } catch (error) {
      return null;
    }
  };

  export { getChildDetails };
  
